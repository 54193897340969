a {
  text-decoration: none;
  color: black;
}

.dashboardHeader {
  background-color: white !important;
  position: sticky;
  top: 0;
  z-index: 100;
}

.dashboardHeader,
.dashboardHeader__routes {
  display: flex;
}

.dashboardHeader {
  border-bottom: 1px solid lightgray;
  justify-content: space-evenly;
  align-items: center;
  height: 9vh;
}

.dashboardHeader__logo img {
  width: 150px;
}

.dashboardHeader__routes {
  align-items: center;
}

.dashboardHeader__routes a {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  font-weight: 600;
  white-space: nowrap;
}

.dashboardHeader__routes input {
  outline: 0px;
  width: 100%;
  font-size: 14px;
  line-height: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  color: rgb(16, 8, 38);
  padding: 12px 10px 12px 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 205, 217);
  box-sizing: border-box;
  border-radius: 43px;
  transition: box-shadow 0.1s ease 0s, width 0.1s ease 0s;
  height: 36px;
}

.oute {
  color: #5d57fb !important;
  background: #f5f6fc;
  border-radius: 20px;
}

.collaps-btn {
  display: none;
}

.right-icons {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .dashboardHeader__routes {
    position: absolute;
    top: 9vh;
    left: 0;
    padding: 20px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid lightgray;
  }

  .hide {
    display: none;
  }
  .collaps-btn {
    display: block;
  }
}
