.dashboard__body > .userInfo {
  margin: 60px auto 30px;
  max-width: 1100px;
  width: 100%;
  display: flex;
}

.dashboard__body > .userInfo > .left {
  flex: 1 1;
  border-right: 1px solid lightgray;
  margin-right: 70px;
}

.dashboard__body > .userInfo > .left > div:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.userInfo__points {
  font-weight: 600;
  font-size: 28px;
  color: #5d57fb;
  line-height: 125%;
}

.userInfo__name {
  font-weight: 600;
  font-size: 28px;
  line-height: 125%;
  color: #100826;
  margin: 0 12px 0 8px;
}

.profileImg {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 12px;
  object-fit: cover;
  display: flex;
  cursor: inherit;
}

.userEarningPoints {
  display: flex;
  margin-bottom: 28px;
  align-items: center;
}

.userEarningPoints div:first-child {
  font-weight: 600;
  font-size: 22px;
  line-height: 125%;
  color: #100826;
  /*margin-bottom: 12px;*/
}

.userEarningPoints > a > img {
  width: 160px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 32px;
}

.userPointsProgress__container {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-left: 0;
  /*background: #e9e9fb;*/
  border-radius: 40px;
  width: 400px;
  box-sizing: border-box;
}

.userPointsProgress__container > div:first-child {
  background: #f2f2ff;
  border-radius: 40px;
  height: 20px;
  width: 100%;
}

.userPointsProgress__container > div:last-child {
  margin-left: 12px;
  font-size: 12px;
  line-height: 100%;
  color: #acaebf;
  display: flex;
  align-items: center;
}

.userPointsProgress__container > div:last-child svg {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  margin-right: 4px;
}

.userPointsProgress__container > div:first-child > span {
  width: 75%;
  display: inherit;
  height: 100%;
  background: #5d57fb;
  /*box-shadow: 0 2px 4px rgba(93,87,251,.3);*/
  border-radius: 40px;
}

.userEarningPoints__more {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #acaebf;
  display: flex;
  align-items: center;
}

.userEarningPoints__more img {
  width: 26.6px;
  height: 20px;
  opacity: 0.5;
  margin: 0 4px;
}

.userEarningPoints__more img:first-child {
  margin-left: 0;
}

.userEarningPoints__more > span {
  font-size: 12px;
  line-height: 20px;
  color: #acaebf;
  display: flex;
  font-weight: normal;
}

.myApplications {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.myApplications > ul:first-child {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.myApplications > ul:first-child li div:first-child {
  font-weight: bold;
}

.myApplications > ul:first-child li div:last-child {
  font-size: small;
  color: gray;
}

.myApplications > ul:first-child li {
  border-radius: 6px;
  background-color: #f5f7fa;
  padding: 15px 12px 14px 16px;
}

.myApplications > ul:last-child {
  padding: 15px 12px 14px 16px;
}

.myApplications > ul:last-child span {
  color: gray;
  font-size: 14px;
}

.myApplications > ul:last-child li {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.earnablePoints {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9fafb;
  padding: 30px 0;
}

.earnablePoints > h3 {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
}

.earnablePoints > h1 {
  font-size: 2em !important;
  font-weight: bold;
  margin-bottom: 1.5em;
}
