.bt1 {
  padding: 8px 16px;
  color: rgb(248, 245, 245);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  /* margin-right: 24px; */
  /* padding-left: 8px;
  padding-right: 8px; */
  cursor: pointer;
  border: none;
  background: none;
}

.bt2 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 36px;
  background: rgba(118, 120, 140, 0.3);
  color: #fff !important;
  cursor: pointer;
  margin-left: 2rem;
}

.land-head {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  align-items: center;
  margin-top: 40px;
  overflow: hidden;
  /* position: fixed; */
  box-sizing: border-box;
  /* position: fixed; */
}
.btn {
  display: flex;
}
.main {
  background: url("../media/classroom7.jpg");

  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  min-height: 130vh;
  background-size: cover;
  object-fit: contain;
  /* z-index: 1; */
  /* flex-direction: column; */
  height: 100%;
  /* position: relative; */
}
.hero {
  max-width: 800px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 9rem;
}
.heading {
  margin-bottom: 40px;
  font-weight: 900;
  font-size: 70px;
  line-height: 125%;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.sub-head {
  font-size: 24px;
  line-height: 142%;
  max-width: 520px;
  /* margin-bottom: 7rem;
  padding-bottom: 7rem; */
  margin-left: auto;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  margin-right: auto;

  font-weight: 700;
  text-align: center;
  color: black;
}
.block-1 {
  background: rgb(51, 49, 49);
  border-radius: 20px;
  padding: 40px;
  width: 360px;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: 0 8px 6px -6px black;
  /* margin-bottom: 9rem; */
}
.block-2 {
  background: rgb(51, 49, 49);
  border-radius: 20px;
  padding: 40px;
  width: 360px;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: 0 8px 6px -6px black;
}
.blocks {
  max-width: 800px;
  position: absolute;
  display: flex;

  justify-content: space-around;
}
.mid {
  background: radial-gradient(at center center, #2d7e9270, #1c40617c 100%),
    url("../media//sch.jpg");

  background-position: center;

  background-repeat: no-repeat;
  min-height: 130vh;
  /* margin-top: 2rem; */
  height: 60px;
  /* position: relative; */
}
.edu {
  max-width: 600px;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  padding-top: 12rem;
}
.edu-head {
  max-width: 600px;
  z-index: 1;
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(111, 111, 119);

  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
.edu-p {
  font-size: 26px;
  line-height: 150%;
  text-align: center;
  color: #fff;
  margin-bottom: 6rem;
  padding-top: 5rem;
}
.bt0 {
  background: #1b1430;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 12px;
  padding: 25px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.2rem;
  align-items: center;
  /* height: 104px; */
  box-sizing: border-box;
  margin-left: 2rem;
  border: transparent;
  cursor: pointer;
  /* margin-top: 4rem; */
}
.b1 {
  display: flex;
  justify-content: space-around;
  padding: 2rem;
  width: 95%;
}
.im1 {
  /* position: absolute; */

  width: 400px;
  margin-top: 2.4rem;
  height: 100%;
  /* object-fit: cover; */
  object-position: center center;
  opacity: 1;
  transition: none 0s ease 0s;
  border-style: none;
}
.un-head {
  font-weight: 800;
  font-size: 44px;
  line-height: 125%;
  color: #100826;
  margin-top: 0;
  margin-bottom: 40px;
}
.text1 {
  /* margin-left: 4rem; */
  margin-top: 2rem;
}
.un-p {
  font-size: 24px;
  line-height: 150%;
  color: #100826;
  margin: 0;
}
.bt1-drop {
  /* position: absolute; */
  /* transform: translate(-50%, -50%); */
  padding: 8px 16px;
  color: rgb(248, 245, 245);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: none;
  /* opacity: 0.9; */
}
.bt1-drop select {
  /* background: white; */
  color: black;
}
option {
  color: black;
  padding: 2rem;
  background-color: white;
}
.bt0 :hover {
  background-color: hotpink;
  transition: background-color 2s ease-out;

  color: #c21e42;
}
/* .dropdown {
  position: absolute;
  /* top: 100%;
  left: 0; */
/* width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
} */

/* ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
button {
  padding: 8px 16px;
  color: rgb(248, 245, 245);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: none;
} */
.imshaw2 {
  border: 0.5px solid rgb(201, 198, 198);
  border-radius: 5px;
  box-shadow: 10px 10px 5px rgb(78, 77, 77);
}

@media screen and (max-width: 780px) {
  .langingPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }

  .land-head {
    width: 100vw;
    margin-top: 0px;
    padding-top: 0.8rem;
  }

  .hero {
    height: fit-content;
  }

  .bt2 {
    margin-left: 0;
  }

  .log-pic {
    width: 150px !important;
  }

  .heading {
    font-size: 50px;
  }

  .sub-head {
    font-size: 18px;
    padding: 0 8px;
  }

  .edu-p {
    font-size: 18px;
    padding: 0 15px;
  }

  .edu-head {
    font-weight: 800;
    font-size: 51px;
    padding-bottom: 20px;
  }

  .apllibtn {
    margin-top: 20px;
  }

  .bt0 {
    width: 80%;
    margin-left: 0;
  }
  .lady-1 {
    margin: 0 !important;
  }
  .b1 {
    align-items: center;
    flex-direction: column;
    padding: 0 !important;
  }
  .text1 {
    margin-right: 0 !important;
  }
  .un-head {
    text-align: center;
  }

  .un-p {
    text-align: center;
    margin-left: 5px;
  }

  .imshaw {
    margin-left: 12px;
  }

  .bbtn {
    width: 90% !important;
  }
}
