.top-section {
  width: 90%;
  margin: 3rem;
}
.row-sec {
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
  margin-left: 2rem;
}
.contenmt-sec {
  margin-left: 4rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
}
.step-h1 {
  font-size: 2rem;

  font-weight: 700;
  color: gray;
}
.step-text {
  font-size: 3rem;

  font-weight: 700;
  margin-top: 0.8rem;
  color: rgb(93, 151, 93);
}
.step-desc {
  font-size: 1.2rem;

  font-weight: 500;
  margin-top: 0.8rem;
  line-height: 2rem;
  color: rgb(46, 44, 44);
}
.hr-line {
  width: 100px;
  margin-left: 0.1rem;
}
