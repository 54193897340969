.mod-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 100px 0;
}

.mod-flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.topload {
  font-family: "Raleway", Helvetica, sans-serif;
  letter-spacing: 2px;
  color: rgb(3, 3, 3);
  background-color: rgb(252, 248, 247);

  line-height: 1.5;
}

.mod-text-center {
  text-align: center;
}

.mod-h2 {
  font-size: 1.5rem;
  font-weight: 800;
  padding-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(255, 255, 255, 0.125);
}

.mod-para {
  font-size: 1rem;
  padding: 20px 0;
}

.mod-btn {
  padding: 0;
  background: transparent;
  font-size: 0.9rem;
  outline: none;
  border: none;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
}

.mod-circle {
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.125);
  font-size: 0.9rem;
  padding: 10px;
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.5rem;
}
a {
  text-decoration: none;
}

.header {
  background-color: rgba(9, 21, 62, 0.756);
}
.logo-image {
  padding-bottom: 2rem;
  text-align: left;
  color: black;
}

.logo-image i {
  display: inline-block;
  width: 72px;
  height: 72px;
  border: 2px solid black;
  border-radius: 50%;
  line-height: 4.6rem;
  font-size: 2rem;
  font-weight: 300;
  vertical-align: baseline;
  color: #005d33;
}

/*HERO SECTION */

.hero-section {
  text-align: left;
}

.hero-heading {
  font-size: 2rem;
  font-weight: 800;
  line-height: 4rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.125);
  margin-bottom: 30px;
}

.hero-para {
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 2rem;
  margin-bottom: 2rem;
}
section {
  display: flex;
}

.leftside {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 3rem;
}

.ch {
  margin-left: 5rem;
  margin-bottom: 2rem;
}
.vison {
  margin-left: 4rem;
  margin-top: 8rem;
}
.vis {
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  margin-left: 3rem;
}
.visp {
  font-weight: 700;
  font-size: 1rem;
}
.trainnew {
  background: whitesmoke;
  border: none;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  transform: translate((-50%, -50%));

  margin-bottom: 3rem;
}
.social-menu {
  margin-left: 5rem;
}
.social-menu ul {
  position: absolute;
  left: 50%;
  margin-top: 2rem;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  display: flex;
}
.social-menu ul li {
  list-style: none;
  margin: 0 10px;
}
.social-menu ul li .fa {
  color: #000000;
  font-size: 25px;
  line-height: 50px;
  transition: 0.5s;
}
.social-menu ul li .fa:hover {
  color: #ffffff;
}
.social-menu ul li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  transition: 0.5s;
  transform: translate(0, 0px);
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
}
.social-menu ul li a:hover {
  transform: rotate(0deg) skew(0deg) translate(0, -10px);
}
.social-menu ul li:nth-child(1) a:hover {
  background-color: #3b5999;
}
.social-menu ul li:nth-child(2) a:hover {
  background-color: #55acee;
}
.social-menu ul li:nth-child(3) a:hover {
  background-color: #e4405f;
}
.social-menu ul li:nth-child(4) a:hover {
  background-color: #cd201f;
}
.social-menu ul li:nth-child(5) a:hover {
  background-color: #0077b5;
}

.services,
.teams {
  height: 400px;
  color: #fff;
}

.services .title::before,
.teams .title::before {
  background: #fff;
}
.services .title::after,
.teams .title::after {
  background: #111;
}
.serv-content {
  display: flex;
}
.services .serv-content .card {
  width: 400px;
  height: 200px;

  background: #222;
  text-align: center;
  border-radius: 6px;
  padding: 35px 25px;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  transition: all 0.3s ease;
}
.services .serv-content .card:hover {
  background: #005d33;
}
.services .serv-content .card .box {
  transition: all 0.3s ease;
}
.services .serv-content .card:hover .box {
  transform: scale(1.05);
}
.services .serv-content .card i {
  font-size: 50px;
  color: #005d33;
  transition: color 0.3s ease;
}
.services .serv-content .card:hover i {
  color: #fff;
}
.services .serv-content .card .text {
  font-size: 25px;
  font-weight: 500;
  margin: 1rem 0;
}
.box p {
  line-height: 2rem;
  letter-spacing: 1px;
}

/* // down */
.container-im {
  /* position: absolute;
  bottom: 50%;
  left: 30%; */
  transform: translate((-50%, -50%));
  width: 500px;
  height: 200px;
  padding: 30px;

  margin-left: 25rem;
  margin-bottom: 5rem;
  background: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}

.logo-im {
  width: 180px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url(https://res.cloudinary.com/saify/image/upload/v1539009756/icon.jpg);
  background-size: cover;
  border-radius: 50%;
  float: left;
  shape-outside: circle();
  margin: 30px 20px 30px 0;
  box-shadow: -2px 2px 400px #808080;
  transform: translate(-70%, -10%);
}

.lg-name {
  font-family: "Marck Script", san-serif;
  transform: translateX(-85px);
  margin: 0 0 5px;
  padding: 0;
  font-size: 30px;
}

.lg-br {
  font-style: italic;
  font-weight: 600;
  transform: translateX(-85px);
  margin: 0 0 20px;
  padding: 0;
}

.bio-im {
  transform: translateX(-95px);
  font-family: "KoHo", sans-serif;
  margin: 0;
  padding: 0;
  text-align: justify;
  line-height: 28px;
}

.social-im {
  display: grid;
  margin-top: 2rem;
  line-height: 2.5;
  transform: translate(260px, -190px);
  width: 40px;
}
.s-btn {
  color: black;
}
