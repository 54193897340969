/* css reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.bt1 {
  padding: 8px 16px;
  color: rgb(248, 245, 245);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  /* margin-right: 24px; */
  /* padding-left: 8px;
  padding-right: 8px; */
  cursor: pointer;
  border: none;
  background: none;
}

.bt2 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 36px;
  background: rgba(118, 120, 140, 0.3);
  color: #fff !important;
  cursor: pointer;
  margin-left: 2rem;
}

.land-head {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: absolute;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 40px;
  overflow: hidden;
  /* position: fixed; */
  box-sizing: border-box;
  /* position: fixed; */
}
.btn {
  display: -webkit-flex;
  display: flex;
}
.main {
  background: url(/static/media/classroom7.0553862b.jpg);

  background-position: center;
  width: 100%;
  background-repeat: no-repeat;
  min-height: 130vh;
  background-size: cover;
  object-fit: contain;
  /* z-index: 1; */
  /* flex-direction: column; */
  height: 100%;
  /* position: relative; */
}
.hero {
  max-width: 800px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  padding-top: 9rem;
}
.heading {
  margin-bottom: 40px;
  font-weight: 900;
  font-size: 70px;
  line-height: 125%;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.sub-head {
  font-size: 24px;
  line-height: 142%;
  max-width: 520px;
  /* margin-bottom: 7rem;
  padding-bottom: 7rem; */
  margin-left: auto;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  margin-right: auto;

  font-weight: 700;
  text-align: center;
  color: black;
}
.block-1 {
  background: rgb(51, 49, 49);
  border-radius: 20px;
  padding: 40px;
  width: 360px;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: 0 8px 6px -6px black;
  /* margin-bottom: 9rem; */
}
.block-2 {
  background: rgb(51, 49, 49);
  border-radius: 20px;
  padding: 40px;
  width: 360px;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: 0 8px 6px -6px black;
}
.blocks {
  max-width: 800px;
  position: absolute;
  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: space-around;

          justify-content: space-around;
}
.mid {
  background: radial-gradient(at center center, #2d7e9270, #1c40617c 100%),
    url(/static/media/sch.a68ef2d0.jpg);

  background-position: center;

  background-repeat: no-repeat;
  min-height: 130vh;
  /* margin-top: 2rem; */
  height: 60px;
  /* position: relative; */
}
.edu {
  max-width: 600px;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  padding-top: 12rem;
}
.edu-head {
  max-width: 600px;
  z-index: 1;
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(111, 111, 119);

  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
.edu-p {
  font-size: 26px;
  line-height: 150%;
  text-align: center;
  color: #fff;
  margin-bottom: 6rem;
  padding-top: 5rem;
}
.bt0 {
  background: #1b1430;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 12px;
  padding: 25px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.2rem;
  -webkit-align-items: center;
          align-items: center;
  /* height: 104px; */
  box-sizing: border-box;
  margin-left: 2rem;
  border: transparent;
  cursor: pointer;
  /* margin-top: 4rem; */
}
.b1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 2rem;
  width: 95%;
}
.im1 {
  /* position: absolute; */

  width: 400px;
  margin-top: 2.4rem;
  height: 100%;
  /* object-fit: cover; */
  object-position: center center;
  opacity: 1;
  transition: none 0s ease 0s;
  border-style: none;
}
.un-head {
  font-weight: 800;
  font-size: 44px;
  line-height: 125%;
  color: #100826;
  margin-top: 0;
  margin-bottom: 40px;
}
.text1 {
  /* margin-left: 4rem; */
  margin-top: 2rem;
}
.un-p {
  font-size: 24px;
  line-height: 150%;
  color: #100826;
  margin: 0;
}
.bt1-drop {
  /* position: absolute; */
  /* transform: translate(-50%, -50%); */
  padding: 8px 16px;
  color: rgb(248, 245, 245);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: none;
  /* opacity: 0.9; */
}
.bt1-drop select {
  /* background: white; */
  color: black;
}
option {
  color: black;
  padding: 2rem;
  background-color: white;
}
.bt0 :hover {
  background-color: hotpink;
  transition: background-color 2s ease-out;

  color: #c21e42;
}
/* .dropdown {
  position: absolute;
  /* top: 100%;
  left: 0; */
/* width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
} */

/* ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
button {
  padding: 8px 16px;
  color: rgb(248, 245, 245);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: none;
} */
.imshaw2 {
  border: 0.5px solid rgb(201, 198, 198);
  border-radius: 5px;
  box-shadow: 10px 10px 5px rgb(78, 77, 77);
}

@media screen and (max-width: 780px) {
  .langingPage {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }

  .land-head {
    width: 100vw;
    margin-top: 0px;
    padding-top: 0.8rem;
  }

  .hero {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .bt2 {
    margin-left: 0;
  }

  .log-pic {
    width: 150px !important;
  }

  .heading {
    font-size: 50px;
  }

  .sub-head {
    font-size: 18px;
    padding: 0 8px;
  }

  .edu-p {
    font-size: 18px;
    padding: 0 15px;
  }

  .edu-head {
    font-weight: 800;
    font-size: 51px;
    padding-bottom: 20px;
  }

  .apllibtn {
    margin-top: 20px;
  }

  .bt0 {
    width: 80%;
    margin-left: 0;
  }
  .lady-1 {
    margin: 0 !important;
  }
  .b1 {
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 !important;
  }
  .text1 {
    margin-right: 0 !important;
  }
  .un-head {
    text-align: center;
  }

  .un-p {
    text-align: center;
    margin-left: 5px;
  }

  .imshaw {
    margin-left: 12px;
  }

  .bbtn {
    width: 90% !important;
  }
}

.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  background-color: #1b1430;
  padding: 30px 0;
}

.foot-1 {
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  font-size: 16px;
  line-height: 29px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px;
  padding-bottom: 90px;
}

.rights {
  color: #6d7b8c;
  white-space: nowrap;
  display: block;
}
.f-btn1 {
  display: inline-block;
  font-size: 14px;
  height: 44px;
  line-height: 44px;
  background: #5d57fb;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.f-btn2 {
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  height: 44px;
  line-height: 44px;
  background: #fff;
  color: #100826;
  border: 1px solid #e8e9ed;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
}
.down-footer {
  width: 60%;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  font-size: 16px;
  line-height: 29px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.doner {
  font-size: 14px;
  line-height: 142%;
  color: white;
  text-transform: uppercase;
  font-weight: 800;
}
.down1 p {
  font-size: 17px;

  line-height: 150%;
  color: white;

  text-decoration: none;
  cursor: pointer;
}
.down2 p {
  font-size: 17px;

  line-height: 150%;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.down3 p {
  font-size: 17px;

  line-height: 150%;
  color: white;

  text-decoration: none;
  cursor: pointer;
}

.doner {
  padding-bottom: 10px !important;
}

@media screen and (max-width: 768px) {
  .down-footer {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .bottomBorder {
    width: 50px;
  }
}

.bottomBorder {
  height: 3px;
  width: 60%;
  background-color: rgb(128, 124, 124);
  border-radius: 20em;
}

.f-btn2 {
  border: 1px solid #1b1430;
  padding: 0 14px;
}
.f-btn1 {
  padding: 0 14px;
}

@media screen and (max-width: 768px) {
  .footer {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .bottomBorder {
    width: 40%;
  }


}

@media screen and (max-width: 383px){
  .footer-btns{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .f-btn2{
    margin-left: 0px;
  }
}

.doner-landing {
  display: -webkit-flex;
  display: flex;
  max-width: 1100px;
  /* margin-left: 10.7rem; */
  margin-right: auto;
  line-height: 1.15;
  /* background-color: violet; */
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
}
.don-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(243, 233, 233, 0.5);
  /* padding-bottom: 3rem; */
  border-radius: 20px;
  padding: 5px;
  width: 360px;
  box-sizing: border-box;
  text-decoration: none;
}
.don-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(241, 229, 229, 0.5);
  border-radius: 20px;
  padding: 5px;
  width: 360px;
  box-sizing: border-box;
  margin-left: 3rem;
  text-decoration: none;
}
.heed > h2 {
  /* margin: 0; */
  font-weight: 800;
  font-size: 32px;
  line-height: 125%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* padding-right: 4rem; */
  color: #005d33;
  padding: 1rem;
}
.don-2 > h2 {
  /* margin: 0; */
  font-weight: 800;
  font-size: 32px;
  line-height: 125%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: 60px;
  color: #005d33;
  padding: 1rem;
}
.don-1 > p {
  margin-top: 25px;
  font-size: 16px;
  line-height: 142%;
  color: #0606d4;
  font-weight: 700;
  margin-bottom: 2rem;
}
.don-2 > p {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 700;

  line-height: 142%;
  color: #222224;
}
.don-1 :hover {
  background-color: #c6c9c7;
  transition: background-color 2s ease-out;
  border-radius: 9px;
  cursor: pointer;
}
.don-2 :hover {
  background-color: #cdd3d0;

  transition: background-color 2s ease-out;
  cursor: pointer;
  border-radius: 9px;
}
.svgg {
  margin-left: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 50%;
  background-color: rgba(93, 87, 251, 0.1);
}

@media screen and (max-width: 780px){
  .doner-landing{
    width: 100vw;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
  .don-2{
    margin-left: 0;

  }

  .don-1,
  .don-2{
    width: 300px !important;
  }
  .don-2 > p{
    padding: 0 15px;
    padding-bottom: 15px;
  }


}

.already {
  display: inline;
  text-decoration: none;
  font-weight: 600;
  color: #5d57fb;
  margin-right: 6rem;
}
.already:hover {
  text-decoration: underline;
}
.ic1 {
  margin-right: 8px;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}
.tog-1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 18px;
  height: 26px;
  color: #5d57fb;
  text-decoration: none;
}
.naavv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: 5rem;
  margin-right: 2rem;

  -webkit-align-items: center;

          align-items: center;
  margin-top: 30px;
}
@media screen and (max-width: 780px) {
  .respic {
    margin-right: 4rem;
    position: relative;
    left: -40px;
  }
  .resal {
    font-size: 15px;
    display: inline-block;
    margin-right: 1rem;
  }
}

.load-main {
  width: 100%;
  position: fixed;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 50;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.load-main svg {
  font-size: 5px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  -webkit-animation: text 0.8s ease-in-out infinite;
          animation: text 0.8s ease-in-out infinite;
}
@-webkit-keyframes text {
  50% {
    opacity: 0.1;
  }
}
@keyframes text {
  50% {
    opacity: 0.1;
  }
}
.load-main polygon {
  stroke-dasharray: 22;
  stroke-dashoffset: 1;
  animation: dash 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    alternate-reverse;
}
@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 234;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 234;
  }
}

.alert-type {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-animation: cssAnimation 3s forwards;
  animation: cssAnimation 3s forwards;
}

@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.doner-grant {
  display: -webkit-flex;
  display: flex;
  margin-top: 5rem;
}
.zahir {
  width: 90%;
  height: 80%;

  opacity: 1;
  transition: none 0s ease 0s;
  border-radius: 2px;
}
.left-sec {
  margin-left: 4rem;
}
.right-sec {
  margin-left: 3rem;
  width: 50%;
}
.left-h1 {
  color: #005d33;
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
}
.left-h1 > span {
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  color: #100826;
  margin-bottom: 20px;
  max-width: 692px;
  margin-top: 20px;
}
.left-p {
  margin: 0px auto 40px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 22px;
  line-height: 150%;
  margin-top: 2.5rem;
}
.grant-btn {
  width: auto;
  display: inline-block;
  font-size: 16px;
  height: 64px;
  line-height: 64px;
  padding-left: 44px;
  padding-right: 44px;
  background: #005d33;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.grant-btn2 {
  width: auto;
  display: inline-block;
  font-size: 16px;
  height: 64px;
  line-height: 64px;
  padding-left: 44px;
  padding-right: 44px;
  color: #005d33;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  margin-left: 3rem;
  cursor: pointer;
}
.per-p1 {
  font-size: 16px;
  line-height: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #76788c;
}
.per-p2 {
  font-size: 16px;
  line-height: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #76788c;
  margin-left: 3rem;
}
.per-p1 > span {
  font-weight: 800;
  margin-right: 4px;
  font-size: 16px;
  line-height: 30px;

  color: #76788c;
}
.per-p2 > span {
  font-weight: 800;
  margin-right: 4px;
  font-size: 16px;
  line-height: 30px;

  color: #76788c;
}
.feature {
  font-weight: 900;
  font-size: 12px;
  line-height: 125%;
  text-transform: uppercase;
  color: #005d33;
  margin-bottom: 32px;
  margin-top: 2rem;
}
.create > h1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 125%;
  text-align: center;
  color: #1a0359;
  margin-bottom: 60px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.create > .create-button {
  width: auto;
  display: inline-block;
  font-size: 14px;
  height: 44px;
  line-height: 44px;
  padding-left: 20px;
  padding-right: 20px;
  background: #005d33;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  margin-left: 36rem;
}
.create1 > h1 {
  font-weight: 800;
  font-size: 44px;
  line-height: 125%;
  margin-top: 120px;
  margin-bottom: 20px;
  color: rgb(16, 8, 38);
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  color: #100826;
}
.create1 > p {
  font-size: 22px;
  line-height: 142%;
  margin: 0 auto 28px;
  max-width: 720px;
  margin-bottom: 8px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}
@media screen and (max-width: 780px) {
  .doner-grant {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    /* width: 400px; */
    text-align: center;
    /* margin-left: 3rem; */
  }
  .zahir {
    width: 100%;
    height: 90%;
    margin-left: 5rem;
  }
  /* .create {
    flex-direction: column;
  } */
  .create-button {
    display: none !important;
  }
  .left-sec {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
}
@media screen and (max-width: 780px) {
  .per-p1 {
    margin-left: 7rem;
  }
  .span-d {
    margin-right: 7rem;
  }
}

.naav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10px;
  /* background-color: rgb(129, 17, 129); */
}
.bt11 {
  padding: 8px 16px;
  color: rgb(19, 18, 18);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: none;
}
.bt22-1 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 36px;
  background: rgba(118, 120, 140, 0.3);
  /* color: rgb(255, 255, 255) !important; */
  margin-left: 0.5rem;
  cursor: pointer;
  border: none;
}
.bt22-2 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 36px;
  background: rgba(118, 120, 140, 0.3);
  /* color: rgb(255, 255, 255) !important; */
  margin-left: 0.5rem;
  cursor: pointer;
  background: #005d33;
  color: #fff !important;
  border: none;
}
.tog {
  width: 228px;
  height: 36px;
  /* padding-left: 4px;
  padding-right: 4px; */
  background: #e8e9ed;
  border-radius: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* max-width: 100%; */
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 5px rgb(0 0 0 / 4%);
}
.tg1 {
  /* background: #005d33; */
  /* background-color: #fff; */
  height: 28px;
  line-height: 28px;
  background-color: transparent;
  border-radius: 100px;
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 4;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  /* background: #005d33; */
  color: #005d33;
  border: 0.1px solid rgb(228, 223, 223);
  /* padding: 0.5rem; */
  /* margin: 0.5rem; */
  text-decoration: none;
  padding-top: 10px;
  text-align: center;
}
.log-pic {
  width: 200px;
}
.company-menu {
  margin-left: 60rem !important;
  margin-top: -210px !important;
}
.tg1Active {
  background: #005d33;
  color: white;
}
.hamburger {
  display: none !important;
}
@media screen and (max-width: 780px) {
  .naav {
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .rightHeader-btn {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .bt22-1 {
    margin-bottom: 20px;
  }
  .bt22-2 {
    margin-bottom: 10px;
  }
  .nav-barlogo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  .hamburger {
    display: -webkit-flex !important;
    display: flex !important;
    margin-left: 15rem;
    cursor: pointer;
  }
  .mobileNav {
    /* display: none !important; */
    display: block !important;
    background-color: #e5e5e5;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
  }
  .mobileNav2 {
    display: none !important;
  }
  .nav-barlogo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
}

.loginbox {
  margin: auto;
  background-color: #fff;
  max-width: 432px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 4px;
  padding-bottom: 28px !important;
  z-index: 0;
  display: block;
  margin-top: 3rem;
  text-align: center;
}
.welcome {
  font-size: 28px;
  line-height: 125%;
  color: #100826;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(111, 111, 119);
}
.uname {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
  display: block;
  color: #100826;
  margin-top: 1rem;
  margin-right: 23.6rem;
}
.pass {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
  color: #100826;
  margin-top: 1rem;
  margin-right: 23.6rem;
}
.u-input {
  border-radius: 4px;
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border: 1px solid #e62e2e14; */

  font-size: 18px;
  line-height: 60px;
  width: 420px;
  box-sizing: border-box;
}
.forgot {
  font-size: 14px;
  line-height: 17px;
  color: #5d57fb;
  float: right;
  margin-top: 1.5rem;
  display: block;
  text-decoration: none;
  padding-bottom: 20px;
  font-weight: 700;
}
.signin {
  background: #5d57fb;
  color: #fff;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  padding: 13px 20px;
  text-align: center;
  border: 0;
  font-size: 14px;
  line-height: 125%;
}
.dont {
  margin-top: 28px;
  margin-bottom: 20px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}
.opt1 {
  margin-left: 2rem;
  color: #5d57fb;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  font-weight: 600;
}
.showhide {
  position: relative;
}
.showhide small {
  position: absolute;
  top: 30%;
  right: 20px;
  color: grey;
  font-weight: 700;
  cursor: pointer;
}

.validinputs {
  margin-top: 0.4rem;
  color: red;
  font-size: 0.7rem;
  font-weight: 700;
  display: -webkit-flex;
  display: flex;
  text-align: center;
}
@media screen and (max-width: 780px) {
}

.join-d {
  margin-top: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
.join-d > h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 100%;
  color: #100826;
  text-align: center;
  margin: 0 auto;
  max-width: 560px;
}
.join-d > p {
  margin: 40px auto 0px;
  padding: 0px;
  color: rgb(16, 8, 38);
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  max-width: 450px;
  font-weight: 600;
}
.btt {
  margin: 44px auto 54px;
  font-size: 20px;
  line-height: 142%;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 100px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
@media screen and (max-width: 780px) {
  .respic {
    margin-right: 4rem;
    position: relative;
    left: -40px;
  }
  .resal {
    font-size: 15px;
    display: inline-block;
    margin-right: 1rem;
  }
}

a {
  text-decoration: none;
  color: black;
}

.dashboardHeader {
  background-color: white !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.dashboardHeader,
.dashboardHeader__routes {
  display: -webkit-flex;
  display: flex;
}

.dashboardHeader {
  border-bottom: 1px solid lightgray;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  height: 9vh;
}

.dashboardHeader__logo img {
  width: 150px;
}

.dashboardHeader__routes {
  -webkit-align-items: center;
          align-items: center;
}

.dashboardHeader__routes a {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  font-weight: 600;
  white-space: nowrap;
}

.dashboardHeader__routes input {
  outline: 0px;
  width: 100%;
  font-size: 14px;
  line-height: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  color: rgb(16, 8, 38);
  padding: 12px 10px 12px 32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 205, 217);
  box-sizing: border-box;
  border-radius: 43px;
  transition: box-shadow 0.1s ease 0s, width 0.1s ease 0s;
  height: 36px;
}

.oute {
  color: #5d57fb !important;
  background: #f5f6fc;
  border-radius: 20px;
}

.collaps-btn {
  display: none;
}

.right-icons {
  margin-left: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .dashboardHeader__routes {
    position: absolute;
    top: 9vh;
    left: 0;
    padding: 20px 20px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid lightgray;
  }

  .hide {
    display: none;
  }
  .collaps-btn {
    display: block;
  }
}

.points{
  border-radius: 8px;
  background-color: white;
  padding: 30px 30px;
  margin: 10px 0;
}

.points>a>div{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 20px;
  gap: 20px;
  max-width: 55vw;
}

.points .post__heading{
  font-weight: bold;
}

.points>a{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.points .points__count{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.un-h4{
  font-weight: 400;
    font-size: 14px;
    line-height: 142%;
    color: #242425;
    margin: 0;
    text-align: left;
}
.dashboard__body > .userInfo {
  margin: 60px auto 30px;
  max-width: 1100px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.dashboard__body > .userInfo > .left {
  -webkit-flex: 1 1;
          flex: 1 1;
  border-right: 1px solid lightgray;
  margin-right: 70px;
}

.dashboard__body > .userInfo > .left > div:first-child {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 28px;
}

.userInfo__points {
  font-weight: 600;
  font-size: 28px;
  color: #5d57fb;
  line-height: 125%;
}

.userInfo__name {
  font-weight: 600;
  font-size: 28px;
  line-height: 125%;
  color: #100826;
  margin: 0 12px 0 8px;
}

.profileImg {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 12px;
  object-fit: cover;
  display: -webkit-flex;
  display: flex;
  cursor: inherit;
}

.userEarningPoints {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 28px;
  -webkit-align-items: center;
          align-items: center;
}

.userEarningPoints div:first-child {
  font-weight: 600;
  font-size: 22px;
  line-height: 125%;
  color: #100826;
  /*margin-bottom: 12px;*/
}

.userEarningPoints > a > img {
  width: 160px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 32px;
}

.userPointsProgress__container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 12px;
  margin-left: 0;
  /*background: #e9e9fb;*/
  border-radius: 40px;
  width: 400px;
  box-sizing: border-box;
}

.userPointsProgress__container > div:first-child {
  background: #f2f2ff;
  border-radius: 40px;
  height: 20px;
  width: 100%;
}

.userPointsProgress__container > div:last-child {
  margin-left: 12px;
  font-size: 12px;
  line-height: 100%;
  color: #acaebf;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.userPointsProgress__container > div:last-child svg {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  margin-right: 4px;
}

.userPointsProgress__container > div:first-child > span {
  width: 75%;
  display: inherit;
  height: 100%;
  background: #5d57fb;
  /*box-shadow: 0 2px 4px rgba(93,87,251,.3);*/
  border-radius: 40px;
}

.userEarningPoints__more {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #acaebf;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.userEarningPoints__more img {
  width: 26.6px;
  height: 20px;
  opacity: 0.5;
  margin: 0 4px;
}

.userEarningPoints__more img:first-child {
  margin-left: 0;
}

.userEarningPoints__more > span {
  font-size: 12px;
  line-height: 20px;
  color: #acaebf;
  display: -webkit-flex;
  display: flex;
  font-weight: normal;
}

.myApplications {
  min-width: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.myApplications > ul:first-child {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.myApplications > ul:first-child li div:first-child {
  font-weight: bold;
}

.myApplications > ul:first-child li div:last-child {
  font-size: small;
  color: gray;
}

.myApplications > ul:first-child li {
  border-radius: 6px;
  background-color: #f5f7fa;
  padding: 15px 12px 14px 16px;
}

.myApplications > ul:last-child {
  padding: 15px 12px 14px 16px;
}

.myApplications > ul:last-child span {
  color: gray;
  font-size: 14px;
}

.myApplications > ul:last-child li {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 8px 0;
}

.earnablePoints {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f9fafb;
  padding: 30px 0;
}

.earnablePoints > h3 {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
}

.earnablePoints > h1 {
  font-size: 2em !important;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.bold{
  font-weight: bold !important;
}

.profile{
  background-color: rgb(247, 250, 252);
  padding-bottom: 30px;
  margin-bottom: 20px;

}




.profile__container{
  max-width: 1100px;
  margin: 40px auto 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profile__containerLeft{
  height: 0%;
  min-width: 300px;
  max-width: 300px;
  margin-right: 60px;
  background: #fff;
  box-shadow: 0 1px 5px rgba(0,0,0,.04), 0 1px 3px rgba(0,0,0,.05);
  border-radius: 4px;
  padding-bottom: 40px;
}

.profile__containerLeft__imageContainer{
  padding: 10px;
}

.profile__containerLeft__imageContainer>div:first-child{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  position: relative;
}


.profile__containerLeft__imageContainer img{
  max-width: 100%;
    min-width: 100px;
    display: block;
    border-radius: 4px;
}





.profile__containerLeft__nomination{
  margin: 10px 0px;
  padding: 4px 20px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.profile__containerLeft__nomination svg{
  max-width: 24px;
  width: 100%;
  height: auto;
  fill: none;
}

.profile__containerLeft__nomination > span{
  padding-left: 12px;
  margin-right: 20px;
  color: rgb(16, 8, 38);
  font-size: 14px;
  line-height: 100%;
}

.profile__containerLeft__socioContainer{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}


.profile__containerLeft__socioContainer>li{
  min-width: 0px;
  background-color: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profile__containerLeft__socioContainer>li>div{
  font-size: small;
  color: gray;
  padding: 4px 20px 8px;


}

.profile__containerLeft__socioContainer>li>div:nth-child(2){
  color: black;
}



.profile__containerLeft__bottom{
  font-size: small;
  font-weight: bold;
  padding: 20px 20px 8px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 20px;
  gap: 20px;

}


.profile__containerLeft ul{
  padding-top: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

/*RIGHT SIDE [ MAIN ] */

.profile__containerRight{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
  max-width: 740px;
}



.profile__containerRight__header{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profile__containerRight__header>div:first-child{
  display: -webkit-flex;
  display: flex;
}

.profile__containerRight__headerName,
.profile__containerRight__headerPoints{
  font-size: 22px ;
  line-height: 27px;
  font-weight: 600;
  color: #0b233f;
  margin: 0 20px 20px 0;
}

.profile__containerRight__header>div:last-child{
  width: 168px;

  display: -webkit-flex;

  display: flex;
  place-content: center space-evenly;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(93,87,251,.1);
  color: #5d57fb;
  border-radius: 4px;
  height: 28px;
  font-size: 14px;
  line-height: 17px;
}


.profile__containerRight__profileStrength,
.profileBio,
.profileEducation{

  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.05), 0 1px 5px rgba(0,0,0,.04);
  border-radius: 4px;
  margin-bottom: 15px;

}

.profileBio{
  padding: 20px 28px;
}

.profileBio>h1{
  padding: 0;
}




.profile__containerRight__profileStrength__Header{
  display: -webkit-flex;
  display: flex;
}


.profile__containerRight__profileStrength__Header>div{
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin-right: 20px;
}

.profile__containerRight__profileStrength__Header>div:last-child{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}


.profile__containerRight__profileStrength__Header>div:last-child svg {
    width: 24;
    height: 24;
    fill: none;
}

.profile__containerRight__profileStrength__Header>div:last-child>div{

  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #3929a1;
  margin-left: 8px;

}


.profile__containerRight__profileStrength__info{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #f9fafb;
  border-radius: 4px;
  padding: 17px 14px;
  margin: 0px 28px;

}



.profile__containerRight__profileStrength__infoTxt{
  padding-left: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 20px;
  grid-gap: 10px;
  gap: 10px;
}

.profile__containerRight__profileStrength__infoPoint{
  display: -webkit-flex;
  display: flex;
-webkit-align-items: center;
        align-items: center;
margin-left: auto;
  grid-gap: 10px;
  gap: 10px;
}





.profile__containerRight__profileStrength__infoPoint svg{
  width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
}


.profile__containerRight__profileStrength__infoBottom{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 20px 28px;
}

.profileInfo__nav{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.profileInfo__nav>div{
  padding-left: 4px;
  padding-right: 8px;
  margin-right: 8px;
  display: -webkit-flex;
  display: flex;
}

.profileInfo__nav div div{
  font-size: 14px;
line-height: 142%;
color: #76788c;
}


.profile__addphoto{
  margin-left: auto;
  background: #5d57fb;
  border: 1px solid rgba(0,0,0,.05);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  overflow: hidden;
}



.profileHeading{
  padding: 20px 28px;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #0b233f;
}




.profileBio__link>div:first-child{
  display: -webkit-flex;
  display: flex;
-webkit-align-items: center;
        align-items: center;
background: rgba(52,186,150,.1);
border-radius: 4px;
padding: 7px 0;
margin-top: 12px;
text-decoration: none;
-webkit-justify-content: space-between;
        justify-content: space-between;
width: 100%;

}

.profileBio__link>div>div{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 14px;
  grid-gap: 10px;
  gap: 10px;

}


.profile__emptySection{
  font-size: 14px;
    line-height: 20px;
    color: #8f99a6;
    padding: 20px 20px 10px;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.07);
}

.profile__addSection{
  font-size: 14px;
  line-height: 142%;
  color: #314bbe;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 14px 0;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.07);
}

.profileGoals li {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0 17px;
}

.profileGoals li div{
  width: 50%;
  color: gray;

}

.profileGoals li div:last-child{
  color: black;
}

.ReactModal__Overlay{
  background: rgba(0,0,0,.4) !important;
}

.profile__modelContainer:focus{
  border: none !important;
}


.profile__modelContainer__header{
  display: -webkit-flex;
  display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.profile__modelContainer__header>h3{
  margin: 4px 8px 0px 0px;
color: rgb(16, 8, 38);
line-height: 125%;
font-size: 22px;
font-weight: 600;
}

.profile__modelContainer__header>svg{
  cursor: pointer;
    height: 18px;
    width: 18px;
}

.profile__modelContainer__form{
  display: -webkit-flex;
  display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.profile__modelContainer__formField{
  margin: 10px 0px;
display: -webkit-flex;
display: flex;
-webkit-flex-direction: column;
        flex-direction: column;
}

.profile__modelContainer__formField>span{
  font-size: 14px;
line-height: 17px;
margin-bottom: 8px;
display: block;
}


.profile__modelContainer__formField__RadioButtonGroup{

    width: 520px;
    display: -webkit-flex;
    display: flex;

}

.profile__modelContainer__formField__RadioButtonGroup div{
  margin-right: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;

}

.profile__modelContainer__formField__RadioButtonGroup div input[type=radio]{
  margin-left: 15px;
    position: absolute;
    top: auto;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 17px;
}

.profile__modelContainer__formField__RadioButtonGroup div input[type=radio]+label{
  display: block;
  padding: 15px 15px 15px 2.5em;
  background: #fff;
  border: 1px solid rgba(0,0,0,.1);
  box-sizing: border-box;
  border-radius: 4px;
}


.profile__modelContainer__formField__RadioButtonGroup div input[type=radio]:checked+label,
.profile__modelContainer__formField__RadioButtonGroup div input[type=radio]:hover+label {
    border: 1px solid #314bbe;
}




.profile__saveChangesContainer{
  display: -webkit-flex;
  display: flex;
-webkit-flex-direction: row;
        flex-direction: row;
-webkit-justify-content: space-between;
        justify-content: space-between;
margin: 30px 0;
text-align: center;
}


.profile__saveChangesContainer button{
  width: 148px;
height: 44px;
box-sizing: border-box;
text-align: center;
background: #0069ff;
border: 1px solid rgba(0,105,255,.1);
border-radius: 4px;
font-size: 14px;
line-height: 17px;
color: #fff;
cursor: pointer;
}

.hero-about {
  background: url(/static/media/pexels-photo-3769120.44e64591.jpeg);
  /* position: absolute; */
  width: 100%;

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;
  z-index: 1;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.hero-about > h1 {
  margin-left: 9rem;
  font-weight: 900;
  font-size: 26px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ebebf7;
  padding-top: 100px;
  text-shadow: 1px 1px 1px rgb(22, 18, 18), 3px 3px 5px rgb(111, 111, 119);
}
.land-head2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: absolute;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;

  /* margin-bottom: 10px; */
}
.fight {
  font-weight: 800;
  font-size: 70px;
  line-height: 125%;
  margin: 31px 0 40px;
  /* margin-top: 4rem; */
  margin-left: 9rem;
  color: #fff;
  padding-top: 4rem;
  padding-bottom: 40px;
}
.hero-about > p {
  font-size: 22px;
  line-height: 150%;
  color: rgb(8, 8, 8);
  margin-left: 9rem;
  font-weight: 800;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  width: 50%;
}
.block1 {
  display: -webkit-flex;
  display: flex;
  padding-top: 120px;

  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.block1 > h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  margin-right: 90px;
  min-width: 250px;
  color: #fff;
  margin-left: 2.3rem;
}
.block1 > p {
  font-size: 28px;
  line-height: 150%;
  color: #fff;
  font-weight: 600;
}
@media screen and (max-width: 780px) {
  .undesc {
    font-size: 8px;
  }
  .fight {
    margin-right: 3rem;
  }
  .head-desc {
    font-size: 8px;
    padding-bottom: 2rem;
    color: grey;
  }
  .block1 {
    width: 500px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.sub-touch {
  margin-bottom: 8px;
  padding: 0px;
  color: rgb(23, 17, 37);
  font-size: 18px;
  line-height: 130%;
  max-width: 600px;
  font-weight: 600;
  margin-top: 1rem;
}

.faq-header {
  color: rgb(15, 14, 14);
  font-size: 28px;
  font-weight: 700;

  margin-top: 2rem;
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  color: #100826;
}

.faq-header > p {
  font-size: 18px;
  line-height: 150%;
  color: #100826;
  margin-top: 20px;
  margin-bottom: 0;
  text-transform: none;
}
.faqs {
  width: 100%;
  max-width: 768px;

  margin-left: 4rem;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: rgb(78, 73, 73);
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  color: aliceblue;
  padding-right: 80px;

  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  color: azure;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  color: antiquewhite;
  line-height: 1.5;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.howit-header {
  display: -webkit-flex;
  display: flex;
  margin-top: 3rem;
  margin-left: 3rem;
  padding: 1rem;
}
.how-it-works {
  padding-left: 1rem;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
}
.how-it-works :hover {
  color: #005d33;
}
.hero-imgsec {
  background: url(/static/media/sch.a68ef2d0.jpg);

  width: 100%;
  height: 400px;
  object-fit: cover;
  /* object-position: center center; */
  /* opacity: 1; */
  /* transition: none 0s ease 0s; */
  padding-top: 3rem;
}
.howit-header h2 {
  font-weight: 600;
  color: grey;
  font-size: 1rem;
}
.h2-how {
  font-weight: 600;
  font-size: 40px;
  line-height: 125%;
  /* margin: 0; */
  max-width: 420px;
  max-width: 824px;
  text-align: center;
  color: #fff;
  padding-top: 3rem;
  -webkit-justify-content: center;
          justify-content: center;

  /* position: relative; */
}
.p2-how {
  font-size: 18px;
  line-height: 150%;
  margin: 40px 0;
  max-width: 420px;
  max-width: 824px;

  color: #fff;
  margin-left: 3rem;
}
.hero-how {
  margin-top: 5rem;
  margin-bottom: 3rem;
  /* height: 500px; */
}
.learn-more {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-left: 3rem;
}
.get-start {
  margin-top: 1rem;
  text-align: center;
}
.get-start > h1 {
  /* max-width: 528px; */
  font-weight: 600;
  font-size: 40px;
  line-height: 125%;
  color: #1a0359;
  margin: 0 0 30px;
}
.get-start button {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 2rem;
  cursor: pointer;
}
.free-app {
  text-align: center;
}
.free-app > h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 90px;
  text-align: center;
  color: #100826;
  background: #f5f7fa;
  border-radius: 10px;
  margin-bottom: 10px;
  display: inline-block;
  width: 50%;
}
.po1 {
  /* justify-content: center; */
  margin-bottom: 2rem;
  display: -webkit-flex;
  display: flex;

  padding-bottom: 20px;
  padding-top: 20px;
  margin-left: 29rem;
}
.po1 > p {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin-left: 1rem;
  /* border-bottom: 1px solid #e8e9ed; */
}
.cr-button {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 3rem;
  cursor: pointer;
}
.donor-help h1 {
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  text-align: center;
  color: #100826;
  margin-bottom: 20px;
  margin-top: 32px;
}
.donor-help p {
  margin-top: 0;
  margin-bottom: 78px;
  font-size: 22px;
  line-height: 142%;
  text-align: center;
  color: #100826;
}
.main-tog {
  background-color: #005d33;
  border-radius: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 27%;
  box-sizing: border-box;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 5px rgb(0 0 0 / 4%);
}
.mid-tog {
  width: 376px;
  height: 56px;
  padding-left: 14px;
  padding-right: 14px;
  -webkit-align-items: center;
          align-items: center;
  /* padding-left: 2rem; */
}
.aw-bt {
  background-color: #005d33;

  height: 38px;
  width: 50%;
  line-height: 38px;
  color: #fff;
  border-radius: 100px;
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;
  outline-width: 0;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5rem;
  /* margin-right: .3rem; */
  /* margin-left: 1px; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
  cursor: default;
  padding-left: 9px;
  cursor: pointer;
  border: none;
}

.activebutton {
  background-color: #91b475 !important;
}
.aw-bt2 {
  /* background-color: #5d57fb; */
  background-color: #005d33;
  outline-width: 0;

  height: 38px;
  width: 50%;
  border: none;

  line-height: 38px;
  color: #fff;
  border-radius: 100px;
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5rem;
  /* margin-right: .2rem;
    margin-left: 9px; */
  -webkit-align-items: flex-start;
          align-items: flex-start;
  cursor: default;
  padding-left: 9px;
  cursor: pointer;
}
.aw-bt2:hover {
  background-color: #91b475;
  /* background-color: #1a0359; */
}
.aw-bt:hover {
  background-color: #005d33;
  /* background-color: #1a0359; */
}
.define-block {
  display: -webkit-flex;
  display: flex;
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 5rem;
}
.define-block img {
  /* position: absolute;
    top: 0px;
    left: 0px; */
  width: 40%;
  height: 40%;
  border-style: none;
  object-fit: contain;
  object-position: center center;
  opacity: 1;
  transition: none 0s ease 0s;
}
.define-head {
  max-width: 420px;
}
.define-head h1 {
  font-weight: 900;
  font-size: 22px;
  line-height: 125%;
  color: #100826;
  margin-bottom: 20px;
}
.define-head p {
  font-size: 18px;
  line-height: 150%;
  color: #100826;
}
/* .activeRoute {
  color: #005d33;
} */
@media screen and (max-width: 780px) {
  .top-how-container {
    margin-right: 2rem;
  }
  .how-it-works {
    font-size: 10px;
  }
  .main-tog {
    width: 170vw;
  }
  .mid-tog {
    width: 80vw;
  }
}

.any-main {
  display: -webkit-flex;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  text-decoration: none;

  -webkit-align-items: center;

          align-items: center;
  background-color: #d5eafe;
  margin-right: 2rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  height: 300px;
  margin-left: 2rem;
  border-radius: 6px;
  box-shadow: 0 8px 6px -6px black;
  width: 200px;
  margin-bottom: 2rem;
}
.any-main:hover {
  background-color: cadetblue;
  transition: background-color 2s ease-out;
  cursor: pointer;
}
.svg-back {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #005d33;
}
.main-any {
  display: -webkit-flex;
  display: flex;
}
.any-que {
  margin-left: 3rem;
}
.any-que > h1 {
  font-weight: 900;
  font-size: 32px;
  line-height: 125%;
  color: #005d33;
}
.any-que > p {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #5d57fb;
  margin-top: 10px;
}
@media screen and (max-width: 780px) {
  .any-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .svg-back {
    margin-top: 2rem;
  }
  .cr-button {
    margin-left: 12rem;
  }
  .c2 {
    margin-left: 16rem;
  }
  .main-any {
    margin-top: 2rem;
  }
  .awcr {
    margin-right: 12rem;
  }
  .pricebt {
    margin-right: 9rem;
  }
  .mainsvg {
    position: relative;
    left: -14rem;
  }
}

.ben > h1 {
  /* margin: 120px 0 55px; */
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;
  text-align: center;
  color: rgb(24, 22, 22);
  padding-top: 100px;
}
.ben-1 {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 420px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: black;
  padding: 30px;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
  margin-right: 20px;
  color: #fff;
  padding-top: 100px;
  box-shadow: 0 8px 6px -6px black;
}
.ben-big {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #fff;
  padding-top: 30px;
}
.ben-1 > h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 125%;
  text-align: center;
  color: #fff;
  margin: 70px 0 18px;
}
.ben-1 > p {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #fff;
  opacity: 0.5;
  margin: 0;
}

.pri-main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #d5d6dc;
  margin-top: 2rem;
  box-shadow: inset 0 0 2px #000000;
}
.pri-main > h1 {
  margin-top: 100px;
  color: rgb(16, 8, 38);
  font-size: 70px;
  line-height: 100%;
  font-weight: 800;
  display: block;
  text-shadow: 1px 1px 1px #000;
}
.pri-main > p {
  margin-bottom: 30px;
  margin-top: 2rem;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 22px;
  text-align: center;
  line-height: 150%;
  max-width: 664px;
  text-shadow: 1px 1px 1px rgb(189, 182, 182);
}
.pri-main2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e3e3ec;
}
.pri-main2 > h1 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 54px;
  font-weight: 800;
  display: block;
  text-transform: uppercase;
}
.pri-main2 > p {
  margin-bottom: 8px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 48px;
  margin-top: 2rem;
  padding: 0px;

  text-align: center;
  line-height: 150%;
  max-width: 664px;
  text-shadow: 1px 1px 1px rgb(189, 182, 182);
}
.pri-main2 > h2 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
}
.pri-main2 > li {
  margin-bottom: 8px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 12px;
  margin-top: 2rem;
  padding: 0px;

  text-align: center;
  line-height: 150%;
  max-width: 664px;
  text-shadow: 1px 1px 1px rgb(189, 182, 182);
}

.en-logo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-top: 2rem;
}
.en-head > h2 {
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  color: #100826;
  margin: 36px 0 26px;
  max-width: 710px;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
}
.en-head > p {
  font-size: 22px;
  line-height: 142%;
  color: #333335;
  margin: 0;
  text-align: center;
  font-weight: 600;
  margin-top: 3rem;
}
.en-head > button {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-top: 20px;
  margin-left: 37rem;
  cursor: pointer;
  margin-bottom: 2rem;
}
.profile__saveChangesContainer > button {
  margin-left: 12rem;
}
@media screen and (max-width: 780px) {
  .connectus {
    position: relative;
    left: -22rem;
    width: 30%;
  }
  .newstop {
    margin-left: 9rem;
  }
  .posnext {
    margin-left: 14rem;
  }
  .newim1 {
    margin-bottom: 2rem;
  }
}

.sch-top {
  max-width: 640px;
  margin: auto;
}
.sch-top > h1 {
  margin-bottom: 32px;
  margin-top: 32px;
  color: rgb(16, 8, 38);
  font-size: 54px;
  line-height: 100%;
  font-weight: 800;
  display: block;
}
.sch-top > p {
  margin-bottom: 15px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
}
.sch-top > h2 {
  text-transform: uppercase;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
}

.sc-box {
  width: 90%;
  box-sizing: border-box;
  padding: 30px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 4px;
  background-color: rgb(247, 239, 239);
  box-sizing: border-box;
  display: block;
  color: inherit;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  /* justify-content: space-between; */
  transition: box-shadow 0.3s;
  margin-left: 3rem;
  margin-right: 2rem;
}
.sc-box > img {
  border-radius: 9px;
  width: 60px;
  height: 60px;
  min-width: 160px;
  min-height: 120px;
  margin: 0px 32px 0px 0px;
  object-fit: cover;
}
.sc-head > h3 {
  max-width: 100%;
  font-size: 19px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  padding-right: 40px;
}
.sc-head > span {
  opacity: 0.5;
  color: rgb(16, 8, 38);
  font-size: 14px;
  line-height: 100%;
}
.sc-head > p {
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.5;
}
.apply {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  box-shadow: inset 1px 0 0 rgb(0 0 0 / 7%);
  margin-top: 28px;
  margin-bottom: 28px;
  padding: 0 20px !important;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 4px;
  /* background-color: #fff; */
  box-sizing: border-box;
  display: block;
  color: inherit;
  margin-left: 3rem;
  text-decoration: none;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  transition: box-shadow 0.3s;
}
.apply-bt1 {
  text-decoration: none;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 20px;
  width: 190px;
  height: 44px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  -webkit-justify-content: center;
          justify-content: center;
}
.apply-bt2 {
  margin-top: 1rem;
  border: 1px solid rgba(93, 87, 251, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* background-color: blanchedalmond; */
  -webkit-justify-content: center;
          justify-content: center;
  color: #5d57fb;
  /* background: none; */
  padding: 12px 0;
  cursor: pointer;
  width: 190px;
  height: 44px;
  text-decoration: none;
}

.donar-dashhead {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 5rem;
}
.donar-dashhead > h1 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
  margin-left: 1.2rem;
}
.donar-sidehead {
  display: -webkit-flex;
  display: flex;
}
.donar-sidehead > p {
  margin-left: 3rem;
  font-size: 14px;
  font-weight: 600;

  line-height: 17px;
  color: #000;
  opacity: 0.7;
  margin-bottom: 5px;
}
.donar-sidehead > p > span {
  font-weight: 600;
  font-size: 22px;

  line-height: 125%;
  color: #000;
}
.donar-aw-head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #e2f0fe;
  border-radius: 16px;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 280px;
  width: 1100px;
  margin-left: auto;
  box-shadow: 0 8px 6px -6px black;

  margin-right: auto;
}
.donar-award {
  display: -webkit-flex;
  display: flex;
}
.head-d {
  width: 430px;
  max-width: 430px;
}
.head-d > h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 125%;
  color: #100826;
  margin-bottom: 28px;
}
.head-d > p {
  font-size: 16px;
  line-height: 24px;
  color: #100826;
}
.credits {
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 385px;
  max-width: 385px;
  margin-left: 30px;
}
.credits > h2 {
  font-size: 16px;
  line-height: 19px;
  color: #100826;
  margin-bottom: 18px;
  font-weight: bold;
}
.curr {
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  color: #1a0359;
}
.your-s {
  margin: 3rem;
  margin-left: 7rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.your-s > h1 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 800;
}
.your-s > h1 > span {
  font-weight: 200;
  margin-left: 1rem;
}
.your-s .create-schoolarship {
  margin-left: 20px;
  background: #5d57fb;
  border-radius: 4px;
  height: 44px;
  line-height: 44px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 16px;
  text-decoration: none;
  border: none;
  white-space: nowrap;
}

.sc-box {
  box-shadow: 0 8px 6px -6px black !important;
  border-radius: 2px;
  border: 1px solid rgb(211, 207, 207);
  margin-bottom: 1rem;
  box-shadow: 0 8px 6px -6px black;
}

.approved_scholarships {
  margin: 1rem 0;
  background-color: rgb(248, 246, 244);
}

@media screen and (max-width: 768px) {
  .userInfo {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .credits {
    width: 60%;
  }
  .approved_scholarships {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .myApplication__main__scholarships_Container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.pro-doner {
  box-sizing: border-box;
  min-width: 0px;
  margin-top: 2rem;
  margin-left: 28rem;
}
.pro-doner > h1 {
  margin-bottom: 12px;
  margin-top: 0px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
}
.pro-doner > p {
  color: rgb(118, 120, 140);
  font-size: 16px;
  line-height: 100%;
}
.pro-imgd {
  width: 300px;
  height: 300px;
  margin-top: 3rem;
  /* display: block; */
  border-radius: 4px;
  margin-left: 28rem;
}
.img-boxx > h1 {
  margin-top: 20px;
  margin-bottom: 4px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
  margin-left: 28rem;
}
.img-boxx > p {
  color: rgb(93, 87, 251);
  font-size: 14px;
  line-height: 100%;
  text-align: center;
}
.mission {
  margin-left: 28rem;
}
.mission > h1 {
  margin-bottom: 12px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
}
.mission > p {
  margin-bottom: 40px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  max-width: 100%;
}
.mission > button > svg {
  cursor: pointer;
}

.cr-head {
  margin-left: 6rem;
  margin-top: 3rem;
}
.des-head > h1 {
  margin-top: 4rem;
  margin-left: 7rem;

  font-weight: 600;
  color: #100826;
}
.text-area {
  margin-top: 20px;
  border-radius: 4px;
  height: 240px;
  padding: 15px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 18px;
  width: 520px;
  margin-left: 7rem;

  box-sizing: border-box;
  overflow: auto;
}
.brief-des {
  display: -webkit-flex;
  display: flex;
}
.example {
  margin-left: 4rem;
  margin-top: 3rem;
}
.example > p > span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #5d57fb;
  margin-left: 0.7rem;
}
.example > h6 {
  margin-top: 3rem;
  margin-bottom: 8px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  margin-right: 0.7rem;
}
.example > li {
  margin-top: 1.5rem;
  margin-bottom: 2px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  margin-right: 0.7rem;
}
.award_scholar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 700px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 18rem;
  margin-top: 5rem;
}
.award_scholar > h1 {
  margin: 0 0 20px;
  text-align: center;
  font-weight: 600;
  color: #100826;
}
.award_scholar > p {
  font-size: 18px;
  line-height: 142%;
  text-align: center;
  color: #100826;
  margin: 0 0 20px;
  font-weight: 400;
}
.eligible {
  margin-top: 3rem;
  margin-left: 30rem;
}
.eligible > h1 {
  margin: 0 0 20px;
  /* text-align: center; */
  font-weight: 600;
  color: #100826;
}
.mind {
  margin-top: 3rem;
  margin-left: 30rem;
}
.mind > h1 {
  margin-top: 4rem;
  margin-left: 2.4rem;
  font-weight: 600;
  color: #100826;
  margin-bottom: 2rem;
}

.elgi-field {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.field-el > h2 {
  font-weight: 400;
  color: #6d7b8c;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.field-el > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #100826;
}
.main-boxshadow {
  box-shadow: 0 8px 6px -6px rgb(128, 126, 126);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  */
   margin-bottom: 3rem;
  width: 90%;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 4rem;
  background-color: rgb(192, 210, 235);
  margin-top: 1rem;
}
/* .load{
background-color: blue !important;
  position: absolute;
top: 50%;
left: 50%;
margin: -50px 0px 0px -50px;

} */
.set-head > h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  color: #100826;

  margin-bottom: 21px;
  margin-left: 9rem;
  margin-top: 3rem;
}
.set-left {
  margin-left: 3rem;
  margin-right: 5rem;
  border-right: 1px solid #dcdde5;
}
.set-ac {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.set-bt1 {
  margin-left: 1rem;
  /* background-color: rgba(93, 87, 251, 0.1); */
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 44px;
  box-shadow: 0 8px 6px -6px black;

  cursor: pointer;
  color: #666efb;

  /* border-right: 1px solid #dcdde5; */
  margin-bottom: 1rem;
  width: 190px;
  /* box-sizing: border-box; */
}
.set-bt2 {
  margin-left: 1rem;
  /* background-color: rgba(93, 87, 251, 0.1); */
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 8px 6px -6px black;

  height: 44px;
  cursor: pointer;

  /* border-right: 1px solid #dcdde5; */
  margin-bottom: 1rem;
  width: 190px;
  /* box-sizing: border-box; */
}
.set-right > h1 {
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin: 0 0 12px;
  font-weight: 700;
}
.set-right > button {
  background: #fff;
  font-size: 14px;
  line-height: 125%;
  color: #100826;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  padding: 13px 20px;
  text-align: center;
  border: 1px solid #dcdde6;
  box-sizing: border-box;
  box-shadow: 0 8px 6px -6px black;
}
.contact-info {
  text-align: center;
  margin-top: 2rem;
}
.contact-info > h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin: 0 0 4px;
}
.contact-info > p {
  font-size: 14px;
  line-height: 142%;
  color: #76788c;
  margin-bottom: 6px;
}
.con-1st {
  margin: 10px 0px;
  /* display: flex; */
  /* flex-direction: column; */
}
.con-1st > p {
  color: #100826;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
}
.con-1st > input {
  max-height: 44px;
  max-width: 400px;
  line-height: 44px;
  border-radius: 4px;
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 60px;
  width: 520px;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
}
.contact-info > button {
  background: #5d57fb;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 13px 20px;
  margin: 28px 0 0;
  text-align: center;
  border: 0;
  font-size: 14px;
  line-height: 125%;
  margin-bottom: 3rem;
}

.img-sc {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  margin-bottom: 1rem;
}
.single-sch {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 2rem;
}
.img-sc > img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  /* margin-right: 12px; */
  object-fit: cover;
  display: -webkit-flex;
  display: flex;
  cursor: inherit;
}
.img-sc > Link > h4 {
  font-size: 14px;
  line-height: 18px;
  color: #76788c;
  margin-left: 26rem;
}
.left-sing > h2 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 40px !important;
  position: relative;
  padding-right: 40px;
}
.left-sing {
  margin-left: 3rem;

  width: 60%;
}
.two-head > h4 {
  margin-left: 4px;
  font-size: 14px;
  line-height: 100%;
  color: rgba(16, 8, 38, 0.5);
  margin-bottom: 0.6rem;
}
.two-head > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #100826;
}
.big-img {
  display: block;
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-style: none;
  /* margin-left: 3rem; */
}
.des-pp {
  font-size: 18px;
  line-height: 150%;
  color: #100826;
  overflow-wrap: break-word;
  margin-top: 2rem;
}
.right-sing {
  width: 380px;
  min-width: 380px;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  padding: 40px;
  box-sizing: border-box;
  background: #fff;
  /* box-shadow: 0 6px 16px rgb(0 0 0 / 12%); */
  border-radius: 8px;
  margin-right: 3rem;
  box-shadow: 3px 9px 9px -6px black;
}
.right-sing > button {
  border: 1px solid rgba(93, 87, 251, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #5d57fb;
  background: none;
  padding: 12px 0;
  width: 190px;
  height: 44px;
  text-decoration: none;
  margin-top: 3rem;
  width: 100% !important;
  cursor: pointer;
}
.con-1 {
  margin-top: 2.5rem;
}
.con-1 > h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  text-transform: uppercase;
  color: #76788c;
  margin-bottom: 8px;
}
.con-1 > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: #100826;
}
.right-doll > h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #100826;
}
.right-doll > p {
  font-size: 14px;
  line-height: 100%;
  color: #76788c;
  margin-top: 6px;
}
.right-doll > h1 > span {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin-left: 7rem;
  color: #18ab1a;
  background: #f3faf4;
}
.social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2rem;
  /* margin-right: 6rem; */
}
.social > h1 {
  font-weight: 800;
  font-size: 12px;
  line-height: 32px;
  text-transform: uppercase;
  color: #100826;
}
.app-down {
  max-width: 1100px;
  margin: auto;
  text-align: center;
  margin-top: 3rem;
}
.app-down > h1 {
  margin-top: 60px;
  margin-bottom: 20px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
}
.app-down > p {
  color: #5d57fb;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  margin-bottom: 12px;
}
.app-down > h2 {
  font-size: 18px;
  line-height: 150%;
  color: #100826;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  text-align: initial;
}
.app-down > h3 {
  font-size: 14px;
  line-height: 100%;
  color: #76788c;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.apply-sc{
  background: #5d57fb;
  border: 1px solid rgba(0,0,0,.05);
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  padding: 15px 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  white-space: nowrap;
  margin-top: 1rem;
  width: 100%!important;
  cursor: pointer;
}
.more-donar > h1 {
  margin-bottom: 12px;
  margin-top: 0px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
}
.more-donar > p {
  color: rgb(118, 120, 140);
  font-size: 16px;
  line-height: 100%;
}
.more-donar {
  padding: 3rem;
  margin-left: 2rem;
}
.don-details {
  box-sizing: border-box;
  min-width: 0px;
  margin: 40px 20px;
  max-width: 1100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-left: 5rem;
}
.doner-det {
  box-shadow: 0 4px 6px -3px rgb(80, 79, 79);
}
.doner-det > img {
  height: 300px;
  /* min-width: 100px; */
  display: block;
  width: 300px;
  border-radius: 4px;
}
.doner-det > h1 {
  margin-top: 20px;
  margin-bottom: 4px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
}
.doner-det > h4 {
  margin-left: 10px;
  color: rgb(118, 120, 140);
  font-size: 14px;
  line-height: 100%;
}
.doner-det > p {
  margin-top: 12px;
  color: rgb(118, 120, 140);
  font-size: 14px;
  line-height: 100%;
  display: block;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
.doner-right {
  width: 70%;
  background-color: white;
  box-shadow: 0 8px 6px -6px rgb(199, 196, 196);

  margin-left: 3rem;
}
.doner-three {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;

  box-shadow: 0 8px 6px -6px rgb(199, 196, 196);
}
.part1 > h1 {
  margin: 0px 0px 4px;
  font-size: 28px;
  line-height: 125%;
  font-weight: 600;
  color: rgb(93, 87, 251);
  margin-top: 1rem;
  margin-bottom: 0.7rem;
}
.part1 > p {
  color: rgb(104, 103, 107);
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 0.7rem;
}
.bot-doner {
  margin-top: 6rem;
}
.bot-doner > h1 {
  margin-bottom: 12px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
}
.bot-doner > p {
  margin-bottom: 40px;
  padding: 0px;
  color: rgb(43, 43, 44);
  font-size: 16px;
  line-height: 150%;
  max-width: 100%;
}

.changeStatus {
  float: right;
  padding-bottom: 10px;
  padding-right: 10px;
}

.myApplication {
  min-height: 100vh;
  background-color: rgb(248, 246, 244);
}

.myApplication > .myApplication__main {
  padding-top: 36px;
  padding-left: 90px;
  padding-right: 90px;
}

.myApplication__main > h1 {
  color: rgb(33, 33, 34);
  line-height: 125%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 39px;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.myapplication__tabs {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  list-style-type: none;
  margin: 0;

  -webkit-margin-before: 1em;

          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

.myapplication__tabs li {
  padding-bottom: 13px;
  color: #76788c;
  font-weight: 600;
  line-height: 17px;
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.myapplication__tabs li:not(:last-child) {
  margin-right: 28px;
}

.myApplication__main__activeLi {
  color: #5d57fb !important;
  box-shadow: inset 0 -2px 0 #5d57fb;
}

.myapplication__tabs li:hover {
  color: #5d57fb !important;
}

.myapplication__tabs li span {
  background-color: #f2f4f7;
  border-radius: 10px;
  padding: 0 7px;
  margin-left: 3px;
}

.myApplication__main__scholarships {
  background-color: #8e9194;
  border-radius: 2px;
  border: 1px solid rgb(211, 207, 207);
  margin-bottom: 1rem;
  box-shadow: 0 8px 6px -6px black;
}

.myApplication__main__scholarships_Container {
  background-color: #fff;
  padding: 16px 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.myApplication__main__scholarships_Container:hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
}

.finishApplicationBtn > div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.myApplication__main__scholarships_Container > a {
  color: inherit;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.myApplication__main__scholarships_ContainerContent {
  margin-left: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 30px;
  height: 78px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.myApplication__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: #100826;
}

.myscholarship__details {
  width: 500px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.myscholarship__details,
.myscholarship__details li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.myscholarship__details li div:last-child div:first-child {
  font-size: small;
  color: #9597a6;
}

.MuiSvgIcon-root {
  color: rgb(143, 153, 166);
}

.myscholarship__details li div:last-child div:last-child {
  font-weight: bold;
}

.myscholarship__details svg {
  margin-right: 10px;
}

.finishApplicationBtn button {
  background: #5d57fb;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 11px 11px 11px 16px;
  display: -webkit-flex;
  display: flex;
  border: none;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
}
.imshaw {
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #ccc;
}

.invite-box {
  padding: 5rem;
}
.invite-box > h1 {
  font-size: 32px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.invite-box > input {
  width: 100%;
  height: 44px;
  padding: 14px 20px;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  padding-left: 20px;
  padding-right: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 60px;
  width: 520px;
  box-sizing: border-box;

  -webkit-appearance: none;

          appearance: none;
}
.invite-options {
  display: -webkit-flex;
  display: flex;
  margin-top: 2rem;
}
.invite-options > p {
  margin-right: 1.5rem;

  color: rgb(118, 120, 140);
  line-height: 150%;
  font-size: 14px;
  font-weight: 600;
}
.invite-box > h2 {
  color: rgb(83, 83, 85);
  line-height: 150%;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3rem;
  text-transform: uppercase;
}

.leader_main > h1 {
  margin-bottom: 25px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.leader_main {
  margin-top: 3rem;
}
.lead-p {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.lead-p > p {
  color: grey;
  font-size: 15px;
  line-height: 150%;
  max-width: 390px;
  text-align: center;
  margin-left: 1rem;
}

.wrapper {
  text-align: center;
}

.super-alls {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.sidebars {
  width: 320px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: rgb(175, 179, 179);
  z-index: 100;
}
.sidebar-brands {
  padding-left: 2rem;
  padding-top: 1rem;
}
.main-contents {
  margin-left: 320px;
}
.sidebar-menus {
  margin-top: 1.5rem;
}
.sidebar-menus li {
  width: 100%;
  margin-bottom: 1.3rem;
  padding-left: 2rem;
}
.sidebar-menus a {
  display: block;
  color: white;
  font-size: 1.1rem;
}
.sidebar-menus a.active {
  background: seashell;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: rgb(24, 22, 22);
  border-radius: 30px 0px 0px 30px;
}
.sidebar-menus a span:first-child {
  margin-right: 0.5rem;
}
.supheader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  background-color: rgb(240, 240, 245);
  width: calc(100% - 345px);
  top: 0;
  z-index: 100;
}
.search-wrapper {
  border: 1px solid rgb(211, 204, 204);
  border-radius: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  overflow-x: hidden;
}
.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  outline: none;
  border: none;
}
.search-wrapper span {
  display: inline-block;
  padding: 0rem 0.4rem;
  font-size: 1.3rem;
  -webkit-align-items: center;
          align-items: center;
}
.user-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 2rem;
}
.user-wrapper img {
  border-radius: 50%;
  margin-right: 1rem;
}
.user-wrapper h1 {
  margin: 0rem !important;
}
.user-wrapper small {
  display: inline-block;
  color: grey;
  font-size: 0.8rem;
}
main {
  margin-top: 75px;
  padding: 2rem 1.5rem;
  background: rgb(244, 247, 244);
  min-height: calc(100vh - 95px);
}
.cards {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.cards-single {
  margin-right: 0.4rem;
  background-color: rgb(227, 233, 235);
  width: 150px;
  height: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  text-align: center;
  box-shadow: 0 8px 6px -6px rgb(107, 105, 105);
}
.in-card {
  margin-top: 3.3rem;
}
.in-card h1 {
  font-size: 1rem;
  font-weight: 600;
}
.in-card span {
  font-size: 0.8rem;
  color: rgb(114, 117, 114);
}
.in-ic span {
  margin-top: 3rem;
  font-size: 3rem;
  color: rgb(48, 212, 26);
}
table {
  border-collapse: collapse;
}
.recent-grid {
  margin-top: 3.5rem;
  display: grid;
  grid-template-columns: 67% auto;
}
.card {
  background: rgb(248, 250, 245);
  border-radius: 5px;
}
.card-header,
.card-body {
  padding: 1rem;
}
.card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid rgb(241, 239, 243);
}
.card-header button {
  background: cornflowerblue;
  border-radius: 10px;
  font-size: 0.8rem;
  color: aliceblue;
  padding: 0.5rem 1rem;
}
thead tr {
  border-top: 1px solid goldenrod;
  border-bottom: 1px solid goldenrod;
}
thead td {
  font-weight: 700;
}
td {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: grey;
}
td .status {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: black;
}
.customer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 2rem;
}

.mod-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 100px 0;
}

.mod-flex {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.topload {
  font-family: "Raleway", Helvetica, sans-serif;
  letter-spacing: 2px;
  color: rgb(3, 3, 3);
  background-color: rgb(252, 248, 247);

  line-height: 1.5;
}

.mod-text-center {
  text-align: center;
}

.mod-h2 {
  font-size: 1.5rem;
  font-weight: 800;
  padding-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(255, 255, 255, 0.125);
}

.mod-para {
  font-size: 1rem;
  padding: 20px 0;
}

.mod-btn {
  padding: 0;
  background: transparent;
  font-size: 0.9rem;
  outline: none;
  border: none;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
}

.mod-circle {
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.125);
  font-size: 0.9rem;
  padding: 10px;
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.5rem;
}
a {
  text-decoration: none;
}

.header {
  background-color: rgba(9, 21, 62, 0.756);
}
.logo-image {
  padding-bottom: 2rem;
  text-align: left;
  color: black;
}

.logo-image i {
  display: inline-block;
  width: 72px;
  height: 72px;
  border: 2px solid black;
  border-radius: 50%;
  line-height: 4.6rem;
  font-size: 2rem;
  font-weight: 300;
  vertical-align: baseline;
  color: #005d33;
}

/*HERO SECTION */

.hero-section {
  text-align: left;
}

.hero-heading {
  font-size: 2rem;
  font-weight: 800;
  line-height: 4rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.125);
  margin-bottom: 30px;
}

.hero-para {
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 2rem;
  margin-bottom: 2rem;
}
section {
  display: -webkit-flex;
  display: flex;
}

.leftside {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
  margin-left: 3rem;
}

.ch {
  margin-left: 5rem;
  margin-bottom: 2rem;
}
.vison {
  margin-left: 4rem;
  margin-top: 8rem;
}
.vis {
  font-size: 3rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  margin-left: 3rem;
}
.visp {
  font-weight: 700;
  font-size: 1rem;
}
.trainnew {
  background: whitesmoke;
  border: none;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate((-50%, -50%));
          transform: translate((-50%, -50%));

  margin-bottom: 3rem;
}
.social-menu {
  margin-left: 5rem;
}
.social-menu ul {
  position: absolute;
  left: 50%;
  margin-top: 2rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
}
.social-menu ul li {
  list-style: none;
  margin: 0 10px;
}
.social-menu ul li .fa {
  color: #000000;
  font-size: 25px;
  line-height: 50px;
  transition: 0.5s;
}
.social-menu ul li .fa:hover {
  color: #ffffff;
}
.social-menu ul li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  transition: 0.5s;
  -webkit-transform: translate(0, 0px);
          transform: translate(0, 0px);
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
}
.social-menu ul li a:hover {
  -webkit-transform: rotate(0deg) skew(0deg) translate(0, -10px);
          transform: rotate(0deg) skew(0deg) translate(0, -10px);
}
.social-menu ul li:nth-child(1) a:hover {
  background-color: #3b5999;
}
.social-menu ul li:nth-child(2) a:hover {
  background-color: #55acee;
}
.social-menu ul li:nth-child(3) a:hover {
  background-color: #e4405f;
}
.social-menu ul li:nth-child(4) a:hover {
  background-color: #cd201f;
}
.social-menu ul li:nth-child(5) a:hover {
  background-color: #0077b5;
}

.services,
.teams {
  height: 400px;
  color: #fff;
}

.services .title::before,
.teams .title::before {
  background: #fff;
}
.services .title::after,
.teams .title::after {
  background: #111;
}
.serv-content {
  display: -webkit-flex;
  display: flex;
}
.services .serv-content .card {
  width: 400px;
  height: 200px;

  background: #222;
  text-align: center;
  border-radius: 6px;
  padding: 35px 25px;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  transition: all 0.3s ease;
}
.services .serv-content .card:hover {
  background: #005d33;
}
.services .serv-content .card .box {
  transition: all 0.3s ease;
}
.services .serv-content .card:hover .box {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.services .serv-content .card i {
  font-size: 50px;
  color: #005d33;
  transition: color 0.3s ease;
}
.services .serv-content .card:hover i {
  color: #fff;
}
.services .serv-content .card .text {
  font-size: 25px;
  font-weight: 500;
  margin: 1rem 0;
}
.box p {
  line-height: 2rem;
  letter-spacing: 1px;
}

/* // down */
.container-im {
  /* position: absolute;
  bottom: 50%;
  left: 30%; */
  -webkit-transform: translate((-50%, -50%));
          transform: translate((-50%, -50%));
  width: 500px;
  height: 200px;
  padding: 30px;

  margin-left: 25rem;
  margin-bottom: 5rem;
  background: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}

.logo-im {
  width: 180px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url(https://res.cloudinary.com/saify/image/upload/v1539009756/icon.jpg);
  background-size: cover;
  border-radius: 50%;
  float: left;
  -webkit-shape-outside: circle();
          shape-outside: circle();
  margin: 30px 20px 30px 0;
  box-shadow: -2px 2px 400px #808080;
  -webkit-transform: translate(-70%, -10%);
          transform: translate(-70%, -10%);
}

.lg-name {
  font-family: "Marck Script", san-serif;
  -webkit-transform: translateX(-85px);
          transform: translateX(-85px);
  margin: 0 0 5px;
  padding: 0;
  font-size: 30px;
}

.lg-br {
  font-style: italic;
  font-weight: 600;
  -webkit-transform: translateX(-85px);
          transform: translateX(-85px);
  margin: 0 0 20px;
  padding: 0;
}

.bio-im {
  -webkit-transform: translateX(-95px);
          transform: translateX(-95px);
  font-family: "KoHo", sans-serif;
  margin: 0;
  padding: 0;
  text-align: justify;
  line-height: 28px;
}

.social-im {
  display: grid;
  margin-top: 2rem;
  line-height: 2.5;
  -webkit-transform: translate(260px, -190px);
          transform: translate(260px, -190px);
  width: 40px;
}
.s-btn {
  color: black;
}

.top-section {
  width: 90%;
  margin: 3rem;
}
.row-sec {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: 2rem;
  margin-left: 2rem;
}
.contenmt-sec {
  margin-left: 4rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
}
.step-h1 {
  font-size: 2rem;

  font-weight: 700;
  color: gray;
}
.step-text {
  font-size: 3rem;

  font-weight: 700;
  margin-top: 0.8rem;
  color: rgb(93, 151, 93);
}
.step-desc {
  font-size: 1.2rem;

  font-weight: 500;
  margin-top: 0.8rem;
  line-height: 2rem;
  color: rgb(46, 44, 44);
}
.hr-line {
  width: 100px;
  margin-left: 0.1rem;
}

.tabs-section {
  margin: 2rem;
  max-width: 500px;
}
.tab-im {
  width: 100%;
  height: 200px;
}
.tabs {
  max-width: 350px;
}

.tab-im {
  width: 300px;
}
.texttab {
  font-size: 1.6rem;
  margin: 10px;
  font-weight: 500;
  padding: 10px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}
.tabsec {
  margin-top: 3rem;
  margin-left: 3.5rem;
}
.text-mains {
  border-radius: 10px;
}
.texttab:hover {
  background-color: rgb(236, 245, 245);
  border-radius: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #ccc;
  color: #5d975d;
  font-weight: 700;
}
.activeR {
  border-radius: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #ccc;
  color: #5d975d;
  font-weight: 700;
}

.commingSoon{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 70%;
  background-color: lightgray;
  margin: auto;
  border-radius: 10px;
  height: 40vh;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 12px 10px 5px gray;
}

.commingSoon__h1{
  font-size: 50px;
  font-weight: 700;
  color: darkgreen;
}

.commingSoon__p{
  margin-top: 20px;
  font-weight: bold;
  color: #1b1430;
}

