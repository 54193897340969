.set-head > h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  color: #100826;

  margin-bottom: 21px;
  margin-left: 9rem;
  margin-top: 3rem;
}
.set-left {
  margin-left: 3rem;
  margin-right: 5rem;
  border-right: 1px solid #dcdde5;
}
.set-ac {
  display: flex;
  justify-content: center;
}
.set-bt1 {
  margin-left: 1rem;
  /* background-color: rgba(93, 87, 251, 0.1); */
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 44px;
  box-shadow: 0 8px 6px -6px black;

  cursor: pointer;
  color: #666efb;

  /* border-right: 1px solid #dcdde5; */
  margin-bottom: 1rem;
  width: 190px;
  /* box-sizing: border-box; */
}
.set-bt2 {
  margin-left: 1rem;
  /* background-color: rgba(93, 87, 251, 0.1); */
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0 8px 6px -6px black;

  height: 44px;
  cursor: pointer;

  /* border-right: 1px solid #dcdde5; */
  margin-bottom: 1rem;
  width: 190px;
  /* box-sizing: border-box; */
}
.set-right > h1 {
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin: 0 0 12px;
  font-weight: 700;
}
.set-right > button {
  background: #fff;
  font-size: 14px;
  line-height: 125%;
  color: #100826;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  padding: 13px 20px;
  text-align: center;
  border: 1px solid #dcdde6;
  box-sizing: border-box;
  box-shadow: 0 8px 6px -6px black;
}
.contact-info {
  text-align: center;
  margin-top: 2rem;
}
.contact-info > h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin: 0 0 4px;
}
.contact-info > p {
  font-size: 14px;
  line-height: 142%;
  color: #76788c;
  margin-bottom: 6px;
}
.con-1st {
  margin: 10px 0px;
  /* display: flex; */
  /* flex-direction: column; */
}
.con-1st > p {
  color: #100826;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
}
.con-1st > input {
  max-height: 44px;
  max-width: 400px;
  line-height: 44px;
  border-radius: 4px;
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 60px;
  width: 520px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact-info > button {
  background: #5d57fb;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 13px 20px;
  margin: 28px 0 0;
  text-align: center;
  border: 0;
  font-size: 14px;
  line-height: 125%;
  margin-bottom: 3rem;
}
