.leader_main > h1 {
  margin-bottom: 25px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.leader_main {
  margin-top: 3rem;
}
.lead-p {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lead-p > p {
  color: grey;
  font-size: 15px;
  line-height: 150%;
  max-width: 390px;
  text-align: center;
  margin-left: 1rem;
}
