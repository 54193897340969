.howit-header {
  display: flex;
  margin-top: 3rem;
  margin-left: 3rem;
  padding: 1rem;
}
.how-it-works {
  padding-left: 1rem;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
}
.how-it-works :hover {
  color: #005d33;
}
.hero-imgsec {
  background: url("../media//sch.jpg");

  width: 100%;
  height: 400px;
  object-fit: cover;
  /* object-position: center center; */
  /* opacity: 1; */
  /* transition: none 0s ease 0s; */
  padding-top: 3rem;
}
.howit-header h2 {
  font-weight: 600;
  color: grey;
  font-size: 1rem;
}
.h2-how {
  font-weight: 600;
  font-size: 40px;
  line-height: 125%;
  /* margin: 0; */
  max-width: 420px;
  max-width: 824px;
  text-align: center;
  color: #fff;
  padding-top: 3rem;
  justify-content: center;

  /* position: relative; */
}
.p2-how {
  font-size: 18px;
  line-height: 150%;
  margin: 40px 0;
  max-width: 420px;
  max-width: 824px;

  color: #fff;
  margin-left: 3rem;
}
.hero-how {
  margin-top: 5rem;
  margin-bottom: 3rem;
  /* height: 500px; */
}
.learn-more {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-left: 3rem;
}
.get-start {
  margin-top: 1rem;
  text-align: center;
}
.get-start > h1 {
  /* max-width: 528px; */
  font-weight: 600;
  font-size: 40px;
  line-height: 125%;
  color: #1a0359;
  margin: 0 0 30px;
}
.get-start button {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 2rem;
  cursor: pointer;
}
.free-app {
  text-align: center;
}
.free-app > h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 90px;
  text-align: center;
  color: #100826;
  background: #f5f7fa;
  border-radius: 10px;
  margin-bottom: 10px;
  display: inline-block;
  width: 50%;
}
.po1 {
  /* justify-content: center; */
  margin-bottom: 2rem;
  display: flex;

  padding-bottom: 20px;
  padding-top: 20px;
  margin-left: 29rem;
}
.po1 > p {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #100826;
  margin-left: 1rem;
  /* border-bottom: 1px solid #e8e9ed; */
}
.cr-button {
  font-size: 16px;
  line-height: 48px;
  padding: 0 20px;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 3rem;
  cursor: pointer;
}
.donor-help h1 {
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  text-align: center;
  color: #100826;
  margin-bottom: 20px;
  margin-top: 32px;
}
.donor-help p {
  margin-top: 0;
  margin-bottom: 78px;
  font-size: 22px;
  line-height: 142%;
  text-align: center;
  color: #100826;
}
.main-tog {
  background-color: #005d33;
  border-radius: 100px;
  display: flex;
  align-items: center;
  max-width: 27%;
  box-sizing: border-box;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 5px rgb(0 0 0 / 4%);
}
.mid-tog {
  width: 376px;
  height: 56px;
  padding-left: 14px;
  padding-right: 14px;
  align-items: center;
  /* padding-left: 2rem; */
}
.aw-bt {
  background-color: #005d33;

  height: 38px;
  width: 50%;
  line-height: 38px;
  color: #fff;
  border-radius: 100px;
  flex: 1 1;
  border: none;
  outline-width: 0;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5rem;
  /* margin-right: .3rem; */
  /* margin-left: 1px; */
  align-items: flex-start;
  cursor: default;
  padding-left: 9px;
  cursor: pointer;
  border: none;
}

.activebutton {
  background-color: #91b475 !important;
}
.aw-bt2 {
  /* background-color: #5d57fb; */
  background-color: #005d33;
  outline-width: 0;

  height: 38px;
  width: 50%;
  border: none;

  line-height: 38px;
  color: #fff;
  border-radius: 100px;
  flex: 1 1;
  border: none;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5rem;
  /* margin-right: .2rem;
    margin-left: 9px; */
  align-items: flex-start;
  cursor: default;
  padding-left: 9px;
  cursor: pointer;
}
.aw-bt2:hover {
  background-color: #91b475;
  /* background-color: #1a0359; */
}
.aw-bt:hover {
  background-color: #005d33;
  /* background-color: #1a0359; */
}
.define-block {
  display: flex;
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;
  justify-content: space-between;
  margin-top: 5rem;
}
.define-block img {
  /* position: absolute;
    top: 0px;
    left: 0px; */
  width: 40%;
  height: 40%;
  border-style: none;
  object-fit: contain;
  object-position: center center;
  opacity: 1;
  transition: none 0s ease 0s;
}
.define-head {
  max-width: 420px;
}
.define-head h1 {
  font-weight: 900;
  font-size: 22px;
  line-height: 125%;
  color: #100826;
  margin-bottom: 20px;
}
.define-head p {
  font-size: 18px;
  line-height: 150%;
  color: #100826;
}
/* .activeRoute {
  color: #005d33;
} */
@media screen and (max-width: 780px) {
  .top-how-container {
    margin-right: 2rem;
  }
  .how-it-works {
    font-size: 10px;
  }
  .main-tog {
    width: 170vw;
  }
  .mid-tog {
    width: 80vw;
  }
}
