.hero-about {
  background: url("../media//pexels-photo-3769120.jpeg");
  /* position: absolute; */
  width: 100%;

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;
  z-index: 1;
  flex-direction: column;
}

.hero-about > h1 {
  margin-left: 9rem;
  font-weight: 900;
  font-size: 26px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ebebf7;
  padding-top: 100px;
  text-shadow: 1px 1px 1px rgb(22, 18, 18), 3px 3px 5px rgb(111, 111, 119);
}
.land-head2 {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  align-items: center;

  /* margin-bottom: 10px; */
}
.fight {
  font-weight: 800;
  font-size: 70px;
  line-height: 125%;
  margin: 31px 0 40px;
  /* margin-top: 4rem; */
  margin-left: 9rem;
  color: #fff;
  padding-top: 4rem;
  padding-bottom: 40px;
}
.hero-about > p {
  font-size: 22px;
  line-height: 150%;
  color: rgb(8, 8, 8);
  margin-left: 9rem;
  font-weight: 800;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  width: 50%;
}
.block1 {
  display: flex;
  padding-top: 120px;

  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.block1 > h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  margin-right: 90px;
  min-width: 250px;
  color: #fff;
  margin-left: 2.3rem;
}
.block1 > p {
  font-size: 28px;
  line-height: 150%;
  color: #fff;
  font-weight: 600;
}
@media screen and (max-width: 780px) {
  .undesc {
    font-size: 8px;
  }
  .fight {
    margin-right: 3rem;
  }
  .head-desc {
    font-size: 8px;
    padding-bottom: 2rem;
    color: grey;
  }
  .block1 {
    width: 500px;
    display: flex;
    flex-direction: column;
  }
}
