.elgi-field {
  display: flex;
  justify-content: space-around;
}
.field-el > h2 {
  font-weight: 400;
  color: #6d7b8c;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
}
.field-el > p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #100826;
}
.main-boxshadow {
  box-shadow: 0 8px 6px -6px rgb(128, 126, 126);
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  */
   margin-bottom: 3rem;
  width: 90%;
  align-items: center;
  margin-left: 4rem;
  background-color: rgb(192, 210, 235);
  margin-top: 1rem;
}
/* .load{
background-color: blue !important;
  position: absolute;
top: 50%;
left: 50%;
margin: -50px 0px 0px -50px;

} */