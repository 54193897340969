.invite-box {
  padding: 5rem;
}
.invite-box > h1 {
  font-size: 32px;
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.invite-box > input {
  width: 100%;
  height: 44px;
  padding: 14px 20px;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  padding-left: 20px;
  padding-right: 30px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  line-height: 60px;
  width: 520px;
  box-sizing: border-box;

  appearance: none;
}
.invite-options {
  display: flex;
  margin-top: 2rem;
}
.invite-options > p {
  margin-right: 1.5rem;

  color: rgb(118, 120, 140);
  line-height: 150%;
  font-size: 14px;
  font-weight: 600;
}
.invite-box > h2 {
  color: rgb(83, 83, 85);
  line-height: 150%;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3rem;
  text-transform: uppercase;
}
