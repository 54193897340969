.cr-head {
  margin-left: 6rem;
  margin-top: 3rem;
}
.des-head > h1 {
  margin-top: 4rem;
  margin-left: 7rem;

  font-weight: 600;
  color: #100826;
}
.text-area {
  margin-top: 20px;
  border-radius: 4px;
  height: 240px;
  padding: 15px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 18px;
  line-height: 18px;
  width: 520px;
  margin-left: 7rem;

  box-sizing: border-box;
  overflow: auto;
}
.brief-des {
  display: flex;
}
.example {
  margin-left: 4rem;
  margin-top: 3rem;
}
.example > p > span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #5d57fb;
  margin-left: 0.7rem;
}
.example > h6 {
  margin-top: 3rem;
  margin-bottom: 8px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  margin-right: 0.7rem;
}
.example > li {
  margin-top: 1.5rem;
  margin-bottom: 2px;
  padding: 0px;
  color: rgb(16, 8, 38);
  font-size: 16px;
  line-height: 150%;
  margin-right: 0.7rem;
}
.award_scholar {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  justify-content: center;
  margin-left: 18rem;
  margin-top: 5rem;
}
.award_scholar > h1 {
  margin: 0 0 20px;
  text-align: center;
  font-weight: 600;
  color: #100826;
}
.award_scholar > p {
  font-size: 18px;
  line-height: 142%;
  text-align: center;
  color: #100826;
  margin: 0 0 20px;
  font-weight: 400;
}
.eligible {
  margin-top: 3rem;
  margin-left: 30rem;
}
.eligible > h1 {
  margin: 0 0 20px;
  /* text-align: center; */
  font-weight: 600;
  color: #100826;
}
.mind {
  margin-top: 3rem;
  margin-left: 30rem;
}
.mind > h1 {
  margin-top: 4rem;
  margin-left: 2.4rem;
  font-weight: 600;
  color: #100826;
  margin-bottom: 2rem;
}
