.commingSoon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  background-color: lightgray;
  margin: auto;
  border-radius: 10px;
  height: 40vh;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 12px 10px 5px gray;
}

.commingSoon__h1{
  font-size: 50px;
  font-weight: 700;
  color: darkgreen;
}

.commingSoon__p{
  margin-top: 20px;
  font-weight: bold;
  color: #1b1430;
}
