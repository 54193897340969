.join-d {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.join-d > h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 100%;
  color: #100826;
  text-align: center;
  margin: 0 auto;
  max-width: 560px;
}
.join-d > p {
  margin: 40px auto 0px;
  padding: 0px;
  color: rgb(16, 8, 38);
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  max-width: 450px;
  font-weight: 600;
}
.btt {
  margin: 44px auto 54px;
  font-size: 20px;
  line-height: 142%;
  align-items: center;
  text-align: center;
  color: #fff;
  background: #5d57fb;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 20px 100px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
@media screen and (max-width: 780px) {
  .respic {
    margin-right: 4rem;
    position: relative;
    left: -40px;
  }
  .resal {
    font-size: 15px;
    display: inline-block;
    margin-right: 1rem;
  }
}
