.alert-type {
  display: flex;
  justify-content: center;
  -webkit-animation: cssAnimation 3s forwards;
  animation: cssAnimation 3s forwards;
}

@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
