.tabs-section {
  margin: 2rem;
  max-width: 500px;
}
.tab-im {
  width: 100%;
  height: 200px;
}
.tabs {
  max-width: 350px;
}

.tab-im {
  width: 300px;
}
.texttab {
  font-size: 1.6rem;
  margin: 10px;
  font-weight: 500;
  padding: 10px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}
.tabsec {
  margin-top: 3rem;
  margin-left: 3.5rem;
}
.text-mains {
  border-radius: 10px;
}
.texttab:hover {
  background-color: rgb(236, 245, 245);
  border-radius: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #ccc;
  color: #5d975d;
  font-weight: 700;
}
.activeR {
  border-radius: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #ccc;
  color: #5d975d;
  font-weight: 700;
}
