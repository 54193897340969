.faq-header {
  color: rgb(15, 14, 14);
  font-size: 28px;
  font-weight: 700;

  margin-top: 2rem;
  font-weight: 800;
  font-size: 54px;
  line-height: 125%;
  color: #100826;
}

.faq-header > p {
  font-size: 18px;
  line-height: 150%;
  color: #100826;
  margin-top: 20px;
  margin-bottom: 0;
  text-transform: none;
}
.faqs {
  width: 100%;
  max-width: 768px;

  margin-left: 4rem;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background-color: rgb(78, 73, 73);
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  color: aliceblue;
  padding-right: 80px;

  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  color: azure;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  color: antiquewhite;
  line-height: 1.5;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
