.footer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  background-color: #1b1430;
  padding: 30px 0;
}

.foot-1 {
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 16px;
  line-height: 29px;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 90px;
}

.rights {
  color: #6d7b8c;
  white-space: nowrap;
  display: block;
}
.f-btn1 {
  display: inline-block;
  font-size: 14px;
  height: 44px;
  line-height: 44px;
  background: #5d57fb;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.f-btn2 {
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  height: 44px;
  line-height: 44px;
  background: #fff;
  color: #100826;
  border: 1px solid #e8e9ed;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
}
.down-footer {
  width: 60%;
  padding: 20px;
  display: flex;
  gap: 30px;
  font-size: 16px;
  line-height: 29px;
  justify-content: space-between;
}
.doner {
  font-size: 14px;
  line-height: 142%;
  color: white;
  text-transform: uppercase;
  font-weight: 800;
}
.down1 p {
  font-size: 17px;

  line-height: 150%;
  color: white;

  text-decoration: none;
  cursor: pointer;
}
.down2 p {
  font-size: 17px;

  line-height: 150%;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.down3 p {
  font-size: 17px;

  line-height: 150%;
  color: white;

  text-decoration: none;
  cursor: pointer;
}

.doner {
  padding-bottom: 10px !important;
}

@media screen and (max-width: 768px) {
  .down-footer {
    flex-direction: column;
  }

  .bottomBorder {
    width: 50px;
  }
}

.bottomBorder {
  height: 3px;
  width: 60%;
  background-color: rgb(128, 124, 124);
  border-radius: 20em;
}

.f-btn2 {
  border: 1px solid #1b1430;
  padding: 0 14px;
}
.f-btn1 {
  padding: 0 14px;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .bottomBorder {
    width: 40%;
  }


}

@media screen and (max-width: 383px){
  .footer-btns{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .f-btn2{
    margin-left: 0px;
  }
}
