.myApplication {
  min-height: 100vh;
  background-color: rgb(248, 246, 244);
}

.myApplication > .myApplication__main {
  padding-top: 36px;
  padding-left: 90px;
  padding-right: 90px;
}

.myApplication__main > h1 {
  color: rgb(33, 33, 34);
  line-height: 125%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 39px;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.myapplication__tabs {
  display: flex;
  padding: 0;
  list-style-type: none;
  margin: 0;

  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.myapplication__tabs li {
  padding-bottom: 13px;
  color: #76788c;
  font-weight: 600;
  line-height: 17px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.myapplication__tabs li:not(:last-child) {
  margin-right: 28px;
}

.myApplication__main__activeLi {
  color: #5d57fb !important;
  box-shadow: inset 0 -2px 0 #5d57fb;
}

.myapplication__tabs li:hover {
  color: #5d57fb !important;
}

.myapplication__tabs li span {
  background-color: #f2f4f7;
  border-radius: 10px;
  padding: 0 7px;
  margin-left: 3px;
}

.myApplication__main__scholarships {
  background-color: #8e9194;
  border-radius: 2px;
  border: 1px solid rgb(211, 207, 207);
  margin-bottom: 1rem;
  box-shadow: 0 8px 6px -6px black;
}

.myApplication__main__scholarships_Container {
  background-color: #fff;
  padding: 16px 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myApplication__main__scholarships_Container:hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
}

.finishApplicationBtn > div {
  display: flex;
  justify-content: flex-end;
}

.myApplication__main__scholarships_Container > a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.myApplication__main__scholarships_ContainerContent {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  height: 78px;
  justify-content: space-between;
}

.myApplication__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: #100826;
}

.myscholarship__details {
  width: 500px;
  justify-content: space-between;
}

.myscholarship__details,
.myscholarship__details li {
  display: flex;
  align-items: center;
}

.myscholarship__details li div:last-child div:first-child {
  font-size: small;
  color: #9597a6;
}

.MuiSvgIcon-root {
  color: rgb(143, 153, 166);
}

.myscholarship__details li div:last-child div:last-child {
  font-weight: bold;
}

.myscholarship__details svg {
  margin-right: 10px;
}

.finishApplicationBtn button {
  background: #5d57fb;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding: 11px 11px 11px 16px;
  display: flex;
  border: none;
  align-items: center;
  white-space: nowrap;
}
.imshaw {
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 5px #ccc;
}
