.donar-dashhead {
  display: flex;
  justify-content: space-between;
  margin: 5rem;
}
.donar-dashhead > h1 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 32px;
  font-weight: 600;
  margin-left: 1.2rem;
}
.donar-sidehead {
  display: flex;
}
.donar-sidehead > p {
  margin-left: 3rem;
  font-size: 14px;
  font-weight: 600;

  line-height: 17px;
  color: #000;
  opacity: 0.7;
  margin-bottom: 5px;
}
.donar-sidehead > p > span {
  font-weight: 600;
  font-size: 22px;

  line-height: 125%;
  color: #000;
}
.donar-aw-head {
  display: flex;
  align-items: center;
  background: #e2f0fe;
  border-radius: 16px;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 280px;
  width: 1100px;
  margin-left: auto;
  box-shadow: 0 8px 6px -6px black;

  margin-right: auto;
}
.donar-award {
  display: flex;
}
.head-d {
  width: 430px;
  max-width: 430px;
}
.head-d > h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 125%;
  color: #100826;
  margin-bottom: 28px;
}
.head-d > p {
  font-size: 16px;
  line-height: 24px;
  color: #100826;
}
.credits {
  flex-direction: column;
  width: 385px;
  max-width: 385px;
  margin-left: 30px;
}
.credits > h2 {
  font-size: 16px;
  line-height: 19px;
  color: #100826;
  margin-bottom: 18px;
  font-weight: bold;
}
.curr {
  font-weight: 800;
  font-size: 2rem;
  line-height: 100%;
  color: #1a0359;
}
.your-s {
  margin: 3rem;
  margin-left: 7rem;
  display: flex;
  align-items: center;
}
.your-s > h1 {
  color: rgb(16, 8, 38);
  line-height: 125%;
  font-size: 22px;
  font-weight: 800;
}
.your-s > h1 > span {
  font-weight: 200;
  margin-left: 1rem;
}
.your-s .create-schoolarship {
  margin-left: 20px;
  background: #5d57fb;
  border-radius: 4px;
  height: 44px;
  line-height: 44px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 16px;
  text-decoration: none;
  border: none;
  white-space: nowrap;
}

.sc-box {
  box-shadow: 0 8px 6px -6px black !important;
  border-radius: 2px;
  border: 1px solid rgb(211, 207, 207);
  margin-bottom: 1rem;
  box-shadow: 0 8px 6px -6px black;
}

.approved_scholarships {
  margin: 1rem 0;
  background-color: rgb(248, 246, 244);
}

@media screen and (max-width: 768px) {
  .userInfo {
    flex-direction: column;
  }
  .credits {
    width: 60%;
  }
  .approved_scholarships {
    display: flex;
    flex-direction: column;
  }
  .myApplication__main__scholarships_Container {
    display: flex;
    flex-direction: column;
  }
}
