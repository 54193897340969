.naav {
  display: flex;
  justify-content: space-around;

  width: 100%;
  align-items: center;
  margin-top: 10px;
  /* background-color: rgb(129, 17, 129); */
}
.bt11 {
  padding: 8px 16px;
  color: rgb(19, 18, 18);
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  text-decoration: none;
  margin-right: 12px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  border: none;
  background: none;
}
.bt22-1 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 36px;
  background: rgba(118, 120, 140, 0.3);
  /* color: rgb(255, 255, 255) !important; */
  margin-left: 0.5rem;
  cursor: pointer;
  border: none;
}
.bt22-2 {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 36px;
  background: rgba(118, 120, 140, 0.3);
  /* color: rgb(255, 255, 255) !important; */
  margin-left: 0.5rem;
  cursor: pointer;
  background: #005d33;
  color: #fff !important;
  border: none;
}
.tog {
  width: 228px;
  height: 36px;
  /* padding-left: 4px;
  padding-right: 4px; */
  background: #e8e9ed;
  border-radius: 100px;
  display: flex;
  align-items: center;
  /* max-width: 100%; */
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 5px rgb(0 0 0 / 4%);
}
.tg1 {
  /* background: #005d33; */
  /* background-color: #fff; */
  height: 28px;
  line-height: 28px;
  background-color: transparent;
  border-radius: 100px;
  flex: 1 1;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 4;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  /* background: #005d33; */
  color: #005d33;
  border: 0.1px solid rgb(228, 223, 223);
  /* padding: 0.5rem; */
  /* margin: 0.5rem; */
  text-decoration: none;
  padding-top: 10px;
  text-align: center;
}
.log-pic {
  width: 200px;
}
.company-menu {
  margin-left: 60rem !important;
  margin-top: -210px !important;
}
.tg1Active {
  background: #005d33;
  color: white;
}
.hamburger {
  display: none !important;
}
@media screen and (max-width: 780px) {
  .naav {
    flex-direction: column;
    gap: 20px;
  }
  .rightHeader-btn {
    flex-direction: column;
    align-items: center;
  }
  .bt22-1 {
    margin-bottom: 20px;
  }
  .bt22-2 {
    margin-bottom: 10px;
  }
  .nav-barlogo {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  .hamburger {
    display: flex !important;
    margin-left: 15rem;
    cursor: pointer;
  }
  .mobileNav {
    /* display: none !important; */
    display: block !important;
    background-color: #e5e5e5;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
  }
  .mobileNav2 {
    display: none !important;
  }
  .nav-barlogo {
    display: flex;
    justify-content: space-between !important;
  }
}
