.points{
  border-radius: 8px;
  background-color: white;
  padding: 30px 30px;
  margin: 10px 0;
}

.points>a>div{
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 55vw;
}

.points .post__heading{
  font-weight: bold;
}

.points>a{
  display: flex;
  align-items: center;
  gap: 20px;
}

.points .points__count{
  display: flex;
  align-items: center;
  gap: 10px;
}
.un-h4{
  font-weight: 400;
    font-size: 14px;
    line-height: 142%;
    color: #242425;
    margin: 0;
    text-align: left;
}