.already {
  display: inline;
  text-decoration: none;
  font-weight: 600;
  color: #5d57fb;
  margin-right: 6rem;
}
.already:hover {
  text-decoration: underline;
}
.ic1 {
  margin-right: 8px;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}
.tog-1 {
  display: flex;
  align-items: center;
  font-size: 18px;
  height: 26px;
  color: #5d57fb;
  text-decoration: none;
}
.naavv {
  display: flex;
  justify-content: space-between;
  margin-left: 5rem;
  margin-right: 2rem;

  align-items: center;
  margin-top: 30px;
}
@media screen and (max-width: 780px) {
  .respic {
    margin-right: 4rem;
    position: relative;
    left: -40px;
  }
  .resal {
    font-size: 15px;
    display: inline-block;
    margin-right: 1rem;
  }
}
