.super-alls {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.sidebars {
  width: 320px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: rgb(175, 179, 179);
  z-index: 100;
}
.sidebar-brands {
  padding-left: 2rem;
  padding-top: 1rem;
}
.main-contents {
  margin-left: 320px;
}
.sidebar-menus {
  margin-top: 1.5rem;
}
.sidebar-menus li {
  width: 100%;
  margin-bottom: 1.3rem;
  padding-left: 2rem;
}
.sidebar-menus a {
  display: block;
  color: white;
  font-size: 1.1rem;
}
.sidebar-menus a.active {
  background: seashell;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: rgb(24, 22, 22);
  border-radius: 30px 0px 0px 30px;
}
.sidebar-menus a span:first-child {
  margin-right: 0.5rem;
}
.supheader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  background-color: rgb(240, 240, 245);
  width: calc(100% - 345px);
  top: 0;
  z-index: 100;
}
.search-wrapper {
  border: 1px solid rgb(211, 204, 204);
  border-radius: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
}
.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  outline: none;
  border: none;
}
.search-wrapper span {
  display: inline-block;
  padding: 0rem 0.4rem;
  font-size: 1.3rem;
  align-items: center;
}
.user-wrapper {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.user-wrapper img {
  border-radius: 50%;
  margin-right: 1rem;
}
.user-wrapper h1 {
  margin: 0rem !important;
}
.user-wrapper small {
  display: inline-block;
  color: grey;
  font-size: 0.8rem;
}
main {
  margin-top: 75px;
  padding: 2rem 1.5rem;
  background: rgb(244, 247, 244);
  min-height: calc(100vh - 95px);
}
.cards {
  display: flex;
  justify-content: space-between;
}
.cards-single {
  margin-right: 0.4rem;
  background-color: rgb(227, 233, 235);
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  box-shadow: 0 8px 6px -6px rgb(107, 105, 105);
}
.in-card {
  margin-top: 3.3rem;
}
.in-card h1 {
  font-size: 1rem;
  font-weight: 600;
}
.in-card span {
  font-size: 0.8rem;
  color: rgb(114, 117, 114);
}
.in-ic span {
  margin-top: 3rem;
  font-size: 3rem;
  color: rgb(48, 212, 26);
}
table {
  border-collapse: collapse;
}
.recent-grid {
  margin-top: 3.5rem;
  display: grid;
  grid-template-columns: 67% auto;
}
.card {
  background: rgb(248, 250, 245);
  border-radius: 5px;
}
.card-header,
.card-body {
  padding: 1rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(241, 239, 243);
}
.card-header button {
  background: cornflowerblue;
  border-radius: 10px;
  font-size: 0.8rem;
  color: aliceblue;
  padding: 0.5rem 1rem;
}
thead tr {
  border-top: 1px solid goldenrod;
  border-bottom: 1px solid goldenrod;
}
thead td {
  font-weight: 700;
}
td {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: grey;
}
td .status {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: black;
}
.customer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
